import axios from "axios";
import { showMessage } from "app/store/actions/fuse";
import {
  API_SERVER_URL,
  jsonArray,
  getData,
  uploadFiles,
  putFiles,
  deleteFiles,
} from "app/main/config";
import { uploadLoading } from "app/store/actions/fuse";

export const GET_DOOR_CATEGORY = "[DOOR_CATEGORY] GET DOOR CATEGORY";
export function getDoorCategory() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/doorCategory/read/door_category`)
      .then((response) => {
        const { doorCategory, success } = response.data;
        if (success === true) {
          var result = getData(doorCategory, "parent_door_category_id", 0);

          dispatch({
            type: GET_DOOR_CATEGORY,
            doorCategory: result,
          });
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to load DOOR CATEGORY data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

export const ADD_DOOR_CATEGORY = "[DOOR_CATEGORY] ADD DOOR CATEGORY";
export function addDoorCategory(data) {
  var newData = {
    parent_door_category_id: data.parent_door_category_id,
    name: data.name,
    thumbnail: data.thumbnail[0].name,
  };
  const formData = new FormData();
  formData.append("file", data.thumbnail[0].originFileObj);

  return (dispatch, getState) => {
    axios
      .post(`${API_SERVER_URL}/api/doorCategory/insert/door_category`, {
        ...newData,
      })
      .then((res) => {
        dispatch(uploadLoading(true));
        if (process.env.REACT_APP_MODE === "production") {
          uploadFiles(
            data.thumbnail.filter((file) => file.originFileObj !== undefined),
            `uploads/DoorCategory/${
              res.data.doorCategory.guid + "_" + res.data.doorCategory.id
            }/`
          )
            .then((thumbnail) => {
              const uploadData = [
                {
                  fieldName: "thumbnail",
                  type: "single",
                  data: thumbnail,
                },
              ];
              axios
                .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                  id: res.data.doorCategory.id,
                  tableName: "door_category",
                  data: uploadData,
                })
                .then((response) => {
                  const { success } = response.data;
                  dispatch(uploadLoading(false));
                  if (success === true) {
                    dispatch(
                      showMessage({
                        message: "Successfully Inserted",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        variant: "success",
                      })
                    );
                    Promise.all([
                      dispatch({
                        type: ADD_DOOR_CATEGORY,
                      }),
                    ]).then(() => dispatch(getDoorCategory()));
                  } else {
                    console.log(success);
                  }
                });
            })
            .catch((err) => {
              console.log("failed to upload door_category thumbnail. ", err);
              return;
            });
        } else {
          formData.append("guid", res.data.doorCategory.guid);
          formData.append("id", res.data.doorCategory.id);
          axios
            .post(`${API_SERVER_URL}/api/doorCategory/upload`, formData)
            .then((response) => {
              const { success } = response.data;
              dispatch(uploadLoading(false));
              if (success === true) {
                axios.post(`${API_SERVER_URL}/uploads/clear`).then((res) => {
                  if (res.data.success === true) {
                    dispatch(
                      showMessage({
                        message: "Successfully Inserted",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        variant: "success",
                      })
                    );
                    Promise.all([
                      dispatch({
                        type: ADD_DOOR_CATEGORY,
                      }),
                    ]).then(() => dispatch(getDoorCategory()));
                  }
                });
              }
            });
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to add DOOR CATEGORY data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

export const DELETE_DOOR_CATEGORY = "[DOOR_CATEGORY] DELETE DOOR CATEGORY";
export function deleteDoorCategory(record) {
  return (dispatch) => {
    var p1 = new Promise((resolve, reject) => {
      dispatch(uploadLoading(true));
      if (process.env.REACT_APP_MODE === "production") {
        const data = [];
        data.push(record.thumbnail);
        deleteFiles(data)
          .then((res) => {
            resolve("success");
          })
          .catch((err) => {
            console.log("failed to delete door_category thumbnail. ", err);
            reject(err);
          });
      } else {
        resolve("success");
      }
    });
    p1.then((p1Value) => {
      axios
        .post(`${API_SERVER_URL}/api/doorCategory/remove/door_category`, {
          id: record.id,
        })
        .then((response) => {
          const { success } = response.data;
          dispatch(uploadLoading(false));
          if (success === true) {
            dispatch(
              showMessage({
                message: "Successfully Deleted",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "success",
              })
            );
            Promise.all([
              dispatch({
                type: DELETE_DOOR_CATEGORY,
              }),
            ]).then(() => dispatch(getDoorCategory()));
          }
        })
        .catch((err) => {
          dispatch(uploadLoading(false));
          dispatch(
            showMessage({
              message: "Failed to delete DOOR CATEGORY data",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
            })
          );
          console.log("err", err);
        });
    }).catch((err) => {
      dispatch(uploadLoading(false));
      dispatch(
        showMessage({
          message: "AWS S3 DELETE DOOR_CATEGORY ERROR",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
      return;
    });
  };
}

export const UPDATE_DOOR_CATEGORY = "[DOOR_CATEGORY] UPDATE DOOR CATEGORY";
export function updateDoorCategory(data, id, guid) {
  var updateData = {
    id: id,
    parent_door_category_id: data.parent_door_category_id,
    name: data.name,
    thumbnail: jsonArray([data.thumbnail[0]]),
  };

  return (dispatch, getState) => {
    axios
      .post(`${API_SERVER_URL}/api/doorCategory/update/door_category`, {
        ...updateData,
      })
      .then((res) => {
        dispatch(uploadLoading(true));
        if (process.env.REACT_APP_MODE === "production") {
          putFiles(
            data.thumbnail.filter((file) => file.originFileObj !== undefined),
            `uploads/DoorCategory/${guid + "_" + id}/`
          )
            .then((thumbnail) => {
              const uploadData = [
                {
                  fieldName: "thumbnail",
                  type: "single",
                  data: thumbnail,
                },
              ];

              axios
                .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                  id: id,
                  tableName: "door_category",
                  data: uploadData,
                })
                .then((response) => {
                  const { success } = response.data;
                  dispatch(uploadLoading(false));

                  if (success === true) {
                    dispatch(
                      showMessage({
                        message: "Successfully Updated",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        variant: "success",
                      })
                    );
                    Promise.all([
                      dispatch({
                        type: UPDATE_DOOR_CATEGORY,
                      }),
                    ]).then(() => dispatch(getDoorCategory()));
                  } else {
                    console.log(success);
                  }
                });
            })
            .catch((err) => {
              console.log("failed to put door_category thumbnail. ", err);
              return;
            });
        } else {
          if (data.thumbnail[0].originFileObj !== undefined) {
            const formData = new FormData();
            formData.append("file", data.thumbnail[0].originFileObj);
            formData.append("guid", guid);
            formData.append("id", id);
            axios
              .post(`${API_SERVER_URL}/api/doorCategory/upload`, formData)
              .then((response) => {
                const { success } = response.data;
                dispatch(uploadLoading(false));

                if (success === true) {
                  axios.post(`${API_SERVER_URL}/uploads/clear`).then((res) => {
                    if (res.data.success === true) {
                      dispatch(
                        showMessage({
                          message: "Successfully Updated",
                          autoHideDuration: 2000,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          variant: "success",
                        })
                      );
                      Promise.all([
                        dispatch({
                          type: UPDATE_DOOR_CATEGORY,
                        }),
                      ]).then(() => dispatch(getDoorCategory()));
                    }
                  });
                }
              });
          } else {
            dispatch(uploadLoading(false));
            dispatch(
              showMessage({
                message: "Successfully Updated",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "success",
              })
            );
            Promise.all([
              dispatch({
                type: UPDATE_DOOR_CATEGORY,
              }),
            ]).then(() => dispatch(getDoorCategory()));
          }
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to update DOOR CATEGORY data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

export const SET_DOOR_CATEGORY_SORT_ORDER = "[DOOR_CATEGORY] SET SORT ORDER";
export function setSortOrder(data) {
  return (dispatch) => {
    dispatch(uploadLoading(true));
    axios
      .post(`${API_SERVER_URL}/api/base/setSortOrder`, {
        id: data.id,
        sort_order: data.sort_order,
        table_name: "door_category",
      })
      .then((response) => {
        const { success } = response.data;
        if (success === true) {
          dispatch(uploadLoading(false));
          Promise.all([
            dispatch({
              type: SET_DOOR_CATEGORY_SORT_ORDER,
            }),
          ]).then(() => dispatch(getDoorCategory()));
        } else {
          dispatch(uploadLoading(false));
        }
      })
      .catch((err) => {
        dispatch(uploadLoading(false));
        dispatch(
          showMessage({
            message: "Failed to set sort order",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("failed to set sort order in door category.", err);
      });
  };
}

import DoorCategory from "./DoorCategory";
import { authRoles } from "app/auth";
export const DoorCategoryConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/door/category",
      component: DoorCategory,
    },
  ],
};

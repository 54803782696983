import { combineReducers } from "redux";
import project from "./project.reducer";
import downloadState from "./excel-download.reducer";

const reducer = combineReducers({
  project,
  downloadState,
});

export default reducer;

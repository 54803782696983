import { authRoles } from "app/auth";
import DoorColorFamily from "./DoorColorFamily";
export const DoorColorFamilyConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/doorcolorfamily",
      component: DoorColorFamily,
    },
  ],
};

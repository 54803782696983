import { API_SERVER_URL } from "app/main/config";
import { showMessage } from "app/store/actions/fuse";
import axios from "axios";

export const CHANGE_WATING_STATE = "CHANGE_WATING_STATE";

export const downloadExcel = (startDate, endDate) => (dispatch) => {
  // Start waiting
  dispatch({ type: CHANGE_WATING_STATE, payload: true });
  let endLoading = () =>
    dispatch({ type: CHANGE_WATING_STATE, payload: false });

  axios
    .get(`${API_SERVER_URL}/api/project/downloadExcel`, {
      responseType: "blob",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        startDate:
          new Date(startDate).getFullYear() +
          "-" +
          (new Date(startDate).getMonth() + 1) +
          "-" +
          new Date(startDate).getDate(),
        endDate:
          new Date(endDate).getFullYear() +
          "-" +
          (new Date(endDate).getMonth() + 1) +
          "-" +
          new Date(endDate).getDate(),
      },
    })
    .then((res) => {
      // Download xlsx file ( binary file )
      let url = URL.createObjectURL(new Blob([res.data]));
      let link = document.createElement("a");
      link.href = url;
      link.style.display = "none";
      link.setAttribute("download", `projects_${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      endLoading();
    })
    .catch(() => {
      dispatch(
        showMessage({
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 2000,
          message: "Failed to download projects",
          variant: "error",
        })
      );
      endLoading();
    });
};

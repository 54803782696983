import * as Actions from "../actions";

const initialState = {
  wall_color: [],
};

const wallColorReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_WALL_COLOR: {
      return {
        ...state,
        ...action,
      };
    }
    case Actions.ADD_WALL_COLOR: {
      return {
        ...state,
        ...action,
      };
    }
    case Actions.UPDATE_WALL_COLOR: {
      return {
        ...state,
        ...action,
      };
    }
    default: {
      return state;
    }
  }
};

export default wallColorReducer;

import * as Actions from "../../actions/fuse/index";

const initialState = {
  isLoading: false,
};

const loading = function (state = initialState, action) {
  switch (action.type) {
    case Actions.UPLOAD_LOADING: {
      return {
        ...state,
        ...action,
      };
    }
    default: {
      return state;
    }
  }
};

export default loading;

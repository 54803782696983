import DoorHandle from "./DoorHandle";
import { authRoles } from "app/auth";
export const DoorHandleConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/door/handle",
      component: DoorHandle,
    },
  ],
};

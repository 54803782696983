import React, { Component } from "react";
import { beautifyJSON } from "app/main/config";
import { IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Table, Row, Tooltip, Modal, Button } from "antd";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
const defaultPagination = {
  pageSizeOptions: ["10", "20", "50", "70"],
  showSizeChanger: true,
  size: "small",
  position: "top",
};

const styles = (theme) => ({
  button: {
    marginTop: "-12px",
  },
});

class CabinetDoorStyleTableModal extends Component {
  render() {
    const { open, classes } = this.props;

    const columns = [
      {
        key: "cabinet_door_style_name",
        title: "Name",
        dataIndex: "cabinet_door_style_name",
        width: 150,
        render: (text, record) => <span>{text}</span>,
      },
      {
        key: "basic_cds_name",
        title: "Basic CDS",
        dataIndex: "basic_cds",
        width: 150,
        render: (text, record) => <span>{text}</span>,
      },
      {
        key: "base_gltf",
        title: "Main GLTF",
        dataIndex: "base_gltf",
        width: 400,
        render: (text, record) => {
          if (text !== null) {
            const data = beautifyJSON(text);
            const result = [];
            data.forEach((element, key) => {
              result.push(
                <span key={key}>
                  {element}
                  <br />
                </span>
              );
            });
            return (
              <Tooltip
                overlayStyle={{ zIndex: 10000 }}
                placement="topLeft"
                title={result}
              >
                <span>{text.slice(0, 40) + "..."}</span>
              </Tooltip>
            );
          }
          return <span></span>;
        },
      },
      {
        key: "sku",
        title: "SKU",
        dataIndex: "sku",
        width: 150,
        render: (text, record) => <span>{text}</span>,
      },
      {
        key: "door_color_ids",
        title: "Door Color Ids",
        dataIndex: "door_color_ids",
        width: 150,
        render: (text, record) => <span>{text}</span>,
      },
      {
        key: "cabinet_door",
        title: "Cabinet Door",
        dataIndex: "cabinet_door",
        width: 400,
        render: (text, record) => {
          const data = beautifyJSON(text);
          const result = [];
          data.forEach((element, key) => {
            result.push(
              <span key={key}>
                {element}
                <br />
              </span>
            );
          });
          return (
            <Tooltip
              overlayStyle={{ zIndex: 10000 }}
              placement="topLeft"
              title={result}
            >
              <span>{text.slice(0, 40) + "..."}</span>
            </Tooltip>
          );
        },
      },
      {
        key: "cabinet_drawer",
        title: "Cabinet Drawer",
        dataIndex: "cabinet_drawer",
        width: 350,
        render: (text, record) => {
          const data = beautifyJSON(text);
          const result = [];
          data.forEach((element, key) => {
            result.push(
              <span key={key}>
                {element}
                <br />
              </span>
            );
          });
          return (
            <Tooltip
              overlayStyle={{ zIndex: 10000 }}
              placement="topLeft"
              title={result}
            >
              <span>{text.slice(0, 40) + "..."}</span>
            </Tooltip>
          );
        },
      },
      {
        key: "manufacturer_name",
        title: "Manufacturer",
        dataIndex: "manufacturer_name",
        width: 200,
      },
      {
        key: "collection_name",
        title: "Collection",
        dataIndex: "collection_name",
        width: 200,
      },
      {
        key: "door_style_name",
        title: "Door Style",
        dataIndex: "door_style_name",
        // width: 150,
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "id",
        width: 150,
        fixed: "right",
        render: (text, record) => (
          <Row>
            <IconButton
              className={classes.button}
              aria-label="Delete"
              onClick={(e) => {
                e.preventDefault();
                this.props.handleEdit(record);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Edit"
              onClick={(e) => {
                e.preventDefault();
                this.props.handleDelete(record);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Row>
        ),
      },
    ];
    return (
      <Modal
        zIndex={600}
        visible={open}
        title="Cabinet Door Styles"
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        style={{ minWidth: "80%" }}
        footer={[
          <Button key="submit" type="primary" onClick={this.props.handleOk}>
            OK
          </Button>,
        ]}
      >
        <Table
          bordered
          rowKey="id"
          className="mtable"
          pagination={defaultPagination}
          columns={columns}
          dataSource={this.props.defaultValue}
          indentSize={20}
          scroll={{ x: 2450, y: 500 }}
        />
      </Modal>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  CabinetDoorStyleTableModal
);

import * as Actions from "../actions";

const initialState = {
  counterTop: [],
};

const counterTopReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_COUNTER_TOP: {
      return {
        ...state,
        ...action,
      };
    }
    default: {
      return state;
    }
  }
};

export default counterTopReducer;

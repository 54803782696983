import axios from "axios";
import { showMessage } from "app/store/actions/fuse";
import {
  API_SERVER_URL,
  jsonArray,
  shapeSVGValue,
  getData,
  uploadFiles,
  putFiles,
  deleteFiles,
  canUpload,
} from "app/main/config";

import { uploadLoading } from "app/store/actions/fuse";

export const GET_CABINET_CATEGORY = "[CABINET_CATEGORY] GET CABINET CATEGORY";
export function getCabinetCategory() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/cabinetCategory/read/cabinet_category`)
      .then((response) => {
        const { cabinetCategory, success } = response.data;
        if (success === true) {
          var result = getData(cabinetCategory, "parent_category_id", 0);
          dispatch({
            type: GET_CABINET_CATEGORY,
            cabinetCategory: result,
          });
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to load CABINET CATEGORY data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
      });
  };
}

export const ADD_CABINET_CATEGORY = "[CABINET_CATEGORY] ADD CABINET CATEGORY";
export function addCabinetCategory(data) {
  var newData = {
    parent_category_id: data.parent_category_id,
    name: data.name,
    thumbnail: data.thumbnail[0].name,
    shape_svg: shapeSVGValue(data.shape_svg),
  };

  return (dispatch, getState) => {
    axios
      .post(`${API_SERVER_URL}/api/cabinetCategory/insert/cabinet_category`, {
        ...newData,
      })
      .then((res) => {
        dispatch(uploadLoading(true));
        if (process.env.REACT_APP_MODE === "production") {
          uploadFiles(
            data.thumbnail.filter((file) => file.originFileObj !== undefined),
            `uploads/CabinetCategory/${
              res.data.cabinetCategory.guid + "_" + res.data.cabinetCategory.id
            }/`
          )
            .then((thumbnail) => {
              uploadFiles(
                data.shape_svg.filter(
                  (file) => file.originFileObj !== undefined
                ),
                `uploads/CabinetCategory/${
                  res.data.cabinetCategory.guid +
                  "_" +
                  res.data.cabinetCategory.id
                }/shape_svg/`
              )
                .then((shape_svg) => {
                  axios
                    .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                      id: res.data.cabinetCategory.id,
                      tableName: "cabinet_category",
                      data: [
                        {
                          fieldName: "thumbnail",
                          type: "single",
                          data: thumbnail,
                        },
                        {
                          fieldName: "shape_svg",
                          type: "single",
                          data: shape_svg,
                        },
                      ],
                    })
                    .then((response) => {
                      const { success } = response.data;
                      dispatch(uploadLoading(false));
                      if (success === true) {
                        dispatch(
                          showMessage({
                            message: "Successfully Inserted",
                            autoHideDuration: 2000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                            variant: "success",
                          })
                        );
                        Promise.all([
                          dispatch({
                            type: ADD_CABINET_CATEGORY,
                          }),
                        ]).then(() => dispatch(getCabinetCategory()));
                      } else {
                        console.log(success);
                      }
                    });
                })
                .catch((err) => {
                  console.log(
                    "failed to upload cabinet category shape(svg). ",
                    err
                  );
                  return;
                });
            })
            .catch((err) => {
              console.log("failed to upload cabinet category thumbnail. ", err);
              return;
            });
        } else {
          if (
            data.thumbnail[0].originFileObj !== undefined ||
            canUpload(data.shape_svg) === true
          ) {
            const formData = new FormData();
            if (data.thumbnail[0].originFileObj !== undefined) {
              formData.append("file", data.thumbnail[0].originFileObj);
            }
            if (canUpload(data.shape_svg)) {
              data.shape_svg.forEach((fElement) => {
                formData.append("shape_svg", fElement.originFileObj);
              });
            }
            formData.append("guid", res.data.cabinetCategory.guid);
            formData.append("id", res.data.cabinetCategory.id);
            axios
              .post(`${API_SERVER_URL}/api/cabinetCategory/upload`, formData)
              .then((response) => {
                const { success } = response.data;
                dispatch(uploadLoading(false));
                if (success === true) {
                  axios.post(`${API_SERVER_URL}/uploads/clear`).then((res) => {
                    if (res.data.success === true) {
                      dispatch(
                        showMessage({
                          message: "Successfully Inserted",
                          autoHideDuration: 2000,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          variant: "success",
                        })
                      );
                      Promise.all([
                        dispatch({
                          type: ADD_CABINET_CATEGORY,
                        }),
                      ]).then(() => dispatch(getCabinetCategory()));
                    }
                  });
                }
              });
          } else {
            dispatch(uploadLoading(false));
            dispatch(
              showMessage({
                message: "Successfully Inserted",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "success",
              })
            );
            Promise.all([
              dispatch({
                type: ADD_CABINET_CATEGORY,
              }),
            ]).then(() => dispatch(getCabinetCategory()));
          }
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          showMessage({
            message: "Failed to add CABINET CATEGORY data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
      });
  };
}

export const DELETE_CABINET_CATEGORY =
  "[CABINET_CATEGORY] DELETE CABINET CATEGORY";
export function deleteCabinetCategory(record) {
  return (dispatch) => {
    var p1 = new Promise((resolve, reject) => {
      dispatch(uploadLoading(true));
      if (process.env.REACT_APP_MODE === "production") {
        const data = [];
        data.push(record.thumbnail);
        if (record.shape_svg) data.push(record.shape_svg);
        deleteFiles(data)
          .then((res) => {
            resolve("success");
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        resolve("success");
      }
    });
    p1.then((p1Value) => {
      axios
        .post(`${API_SERVER_URL}/api/cabinetCategory/remove/cabinet_category`, {
          id: record.id,
        })
        .then((response) => {
          const { success } = response.data;
          dispatch(uploadLoading(false));
          if (success === true) {
            dispatch(
              showMessage({
                message: "Successfully Deleted",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "success",
              })
            );
            Promise.all([
              dispatch({
                type: DELETE_CABINET_CATEGORY,
              }),
            ]).then(() => dispatch(getCabinetCategory()));
          }
        })
        .catch((err) => {
          dispatch(uploadLoading(false));
          dispatch(
            showMessage({
              message: "Failed to delete CABINET CATEGORY data",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
            })
          );
        });
    }).catch((err) => {
      dispatch(uploadLoading(false));
      dispatch(
        showMessage({
          message: "AWS S3 DELETE CABINET_CATEGORY ERROR",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
      return;
    });
  };
}

export const UPDATE_CABINET_CATEGORY =
  "[CABINET_CATEGORY] UPDATE CABINET CATEGORY";
export function updateCabinetCategory(data, id, guid) {
  var updateData = {
    id: id,
    parent_category_id: data.parent_category_id,
    name: data.name,
    thumbnail: jsonArray([data.thumbnail[0]]),
    shape_svg: shapeSVGValue(data.shape_svg),
  };

  return (dispatch, getState) => {
    axios
      .post(`${API_SERVER_URL}/api/cabinetCategory/update/cabinet_category`, {
        ...updateData,
      })
      .then((res) => {
        dispatch(uploadLoading(true));
        if (process.env.REACT_APP_MODE === "production") {
          putFiles(
            data.thumbnail.filter((file) => file.originFileObj !== undefined),
            `uploads/CabinetCategory/${guid + "_" + id}/`
          )
            .then((thumbnail) => {
              putFiles(
                data.shape_svg.filter(
                  (file) => file.originFileObj !== undefined
                ),
                `uploads/CabinetCategory/${guid + "_" + id}/shape_svg/`
              )
                .then((shape_svg) => {
                  const uploadData = [
                    {
                      fieldName: "shape_svg",
                      type: "single",
                      data: shape_svg,
                    },
                  ];

                  if (thumbnail !== "empty") {
                    uploadData.push({
                      fieldName: "thumbnail",
                      type: "single",
                      data: thumbnail,
                    });
                  }
                  axios
                    .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                      id: id,
                      tableName: "cabinet_category",
                      data: uploadData,
                    })
                    .then((response) => {
                      const { success } = response.data;
                      dispatch(uploadLoading(false));
                      if (success === true) {
                        dispatch(
                          showMessage({
                            message: "Successfully Updated",
                            autoHideDuration: 2000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                            variant: "success",
                          })
                        );
                        Promise.all([
                          dispatch({
                            type: UPDATE_CABINET_CATEGORY,
                          }),
                        ]).then(() => dispatch(getCabinetCategory()));
                      } else {
                        console.log(success);
                      }
                    });
                })
                .catch((err) => {
                  console.log(
                    "failed to put cabinet category shape(svg). ",
                    err
                  );
                  return;
                });
            })
            .catch((err) => {
              console.log("failed to put cabinet category thumbnail. ", err);
              return;
            });
        } else {
          if (
            data.thumbnail[0].originFileObj !== undefined ||
            canUpload(data.shape_svg) === true
          ) {
            const formData = new FormData();
            if (data.thumbnail[0].originFileObj !== undefined) {
              formData.append("file", data.thumbnail[0].originFileObj);
            }
            if (canUpload(data.shape_svg)) {
              data.shape_svg.forEach((fElement) => {
                formData.append("shape_svg", fElement.originFileObj);
              });
            }
            formData.append("guid", guid);
            formData.append("id", id);
            axios
              .post(`${API_SERVER_URL}/api/cabinetCategory/upload`, formData)
              .then((response) => {
                const { success } = response.data;
                dispatch(uploadLoading(false));
                if (success === true) {
                  axios.post(`${API_SERVER_URL}/uploads/clear`).then((res) => {
                    if (res.data.success === true) {
                      dispatch(
                        showMessage({
                          message: "Successfully Updated",
                          autoHideDuration: 2000,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          variant: "success",
                        })
                      );
                      Promise.all([
                        dispatch({
                          type: UPDATE_CABINET_CATEGORY,
                        }),
                      ]).then(() => dispatch(getCabinetCategory()));
                    }
                  });
                }
              });
          } else {
            dispatch(uploadLoading(false));
            dispatch(
              showMessage({
                message: "Successfully Updated",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "success",
              })
            );
            Promise.all([
              dispatch({
                type: UPDATE_CABINET_CATEGORY,
              }),
            ]).then(() => dispatch(getCabinetCategory()));
          }
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to update CABINET CATEGORY data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

export const SET_CABINET_CATEGORY_SORT_ORDER =
  "[CABINET_CATEGORY] SET SORT ORDER";
export function setSortOrder(data) {
  return (dispatch) => {
    dispatch(uploadLoading(true));
    axios
      .post(`${API_SERVER_URL}/api/base/setSortOrder`, {
        id: data.id,
        sort_order: data.sort_order,
        table_name: "cabinet_category",
      })
      .then((response) => {
        const { success } = response.data;
        if (success === true) {
          dispatch(uploadLoading(false));
          Promise.all([
            dispatch({
              type: SET_CABINET_CATEGORY_SORT_ORDER,
            }),
          ]).then(() => dispatch(getCabinetCategory()));
        } else {
          dispatch(uploadLoading(false));
        }
      })
      .catch((err) => {
        dispatch(uploadLoading(false));
        dispatch(
          showMessage({
            message: "Failed to set sort order",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("failed to set sort order in cabinet category.", err);
      });
  };
}

import React, { Component } from "react";
import * as Actions from "./store/actions";
import * as appActions from "app/store/actions";
import reducer from "./store/reducers";
import { withRouter } from "react-router-dom";
import withReducer from "app/store/withReducer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FusePageCarded, FuseAnimate } from "@fuse";
import { IconButton, Button, Drawer, Divider } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  Typography,
  Input,
  Upload,
  Form,
  Spin,
  Modal,
  Row,
  Col,
  Tooltip,
  TreeSelect,
  Select,
  Switch,
  Icon,
  InputNumber,
} from "antd";

var ColorPicker = require("rc-color-picker");
const { Option } = Select;
const { Title } = Typography;
const { TreeNode } = TreeSelect;
const FormItem = Form.Item;
const { confirm } = Modal;
const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 },
};

const defaultPagination = {
  pageSizeOptions: ["10", "20", "50", "70"],
  showSizeChanger: true,
  size: "small",
  position: "top",
};

const styles = (theme) => ({
  button: {
    marginTop: "-12px",
  },
  input: {
    display: "none",
  },
  list: {
    width: 500,
  },
});

class DoorColor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      type: "new",
      id: null,
      fieldData: {
        name: "",
        door_style_id: [],
        collection_id: undefined,
        thumbnail: [],
        texture: [],
        color: "",
        sku: "",
        glossness: 1,
        color_family_id: [],
      },
    };
    this.props.getConstData();
  }

  toggleDrawer = (type, record, open) => () => {
    if (type === "new") {
      this.setState({
        id: null,
        fieldData: {
          name: "",
          door_style_id: [],
          collection_id: undefined,
          thumbnail: [],
          texture: [],
          color: "",
          sku: "",
          glossness: 1,
          color_family_id: [],
        },
      });
    } else if (type === "edit") {
      this.setState({
        id: record.id,
        guid: record.guid,
        fieldData: {
          name: record.name,
          door_style_id: record.door_style_name
            ? record.door_style_id.split(",").map((item) => parseInt(item))
            : [],
          collection_id: record.collection_id,
          thumbnail: [
            {
              uid: 1,
              name: record.thumbnail.split("/").pop(),
              status: "done",
              url: record.thumbnail,
            },
          ],
          texture: [
            {
              uid: 1,
              name: record.texture.split("/").pop(),
              status: "done",
              url: record.texture,
            },
          ],
          color: record.color,
          sku: record.sku,
          glossness: record.glossness,
          color_family_id: record.color_family_id
            ? record.color_family_id.split(",").map((item) => parseInt(item))
            : [],
        },
      });
    }
    this.setState({
      type: type,
      visible: open,
    });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ visible: false });
        const { type, id } = this.state;
        if (type === "new") {
          this.props.addDoorColor(values);
        }
        if (type === "edit" && id !== null) {
          this.props.updateDoorColor(values, id, this.state.guid);
        }
      }
    });
  };

  handleDeleteEvent = (record) => {
    const { deleteDoorColor } = this.props;
    confirm({
      title: "Do you want to delete this Item?",
      onOk() {
        deleteDoorColor(record);
      },
      onCancel() {},
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  moveAction = (type, record) => () => {
    const { doorColor } = this.props;
    const index = doorColor.findIndex((el) => el.id === record.id);
    let swapIndex = -1;
    if (
      type === "up" &&
      index !== 0 &&
      doorColor[index - 1].door_style_id === record.door_style_id
    ) {
      swapIndex = index - 1;
    } else if (
      type === "down" &&
      index !== doorColor.length - 1 &&
      doorColor[index + 1].door_style_id === record.door_style_id
    ) {
      swapIndex = index + 1;
    }
    if (swapIndex !== -1) {
      this.props.setSortOrder({
        id: doorColor[index].id,
        sort_order: doorColor[swapIndex].sort_order,
      });
      this.props.setSortOrder({
        id: doorColor[swapIndex].id,
        sort_order: doorColor[index].sort_order,
      });
    }
  };

  render() {
    const {
      classes,
      doorColor,
      doorColorFamily,
      doorStyle,
      collectionData,
      isLoading,
    } = this.props;
    const { fieldData, visible, type } = this.state;
    const { getFieldDecorator, setFieldsValue, getFieldValue } =
      this.props.form;
    const menuItems = [];
    collectionData.forEach((fElement) => {
      menuItems.push(
        <Option key={fElement.id} value={fElement.id}>
          {fElement.collection_name}
        </Option>
      );
    });
    const colordata = [];
    this.props.doorColorFamilyData.forEach((fElement) => {
      colordata.push(
        <Option key={fElement.id} value={fElement.id}>
          {fElement.name}
        </Option>
      );
    });

    const loop = (data) =>
      data.map((item) => {
        if (item.items === undefined) {
          return (
            <Option
              key={item.id}
              value={item.type === "door-category" ? item.path : item.id}
            >
              {item.path}
            </Option>
          );
        } else {
          return loop(item.items);
        }
      });

    const styleOptions = () => loop(doorStyle);

    const uploadButton = (
      <div>
        <AddIcon />
        <div className="ant-upload-text">
          {type === "new" ? "Upload" : "Change & Upload"}
        </div>
      </div>
    );
    const columns = [
      {
        key: "published",
        title: "Published",
        dataIndex: "is_published",
        width: 100,
        fixed: "left",
        render: (text, record) => {
          return (
            <Row>
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                defaultChecked={text > 0}
                onChange={(value) => {
                  this.props.setPublishedFlag(record.id, value);
                }}
              />
            </Row>
          );
        },
      },
      {
        key: "active",
        title: "Active",
        dataIndex: "is_active",
        width: 100,
        fixed: "left",
        render: (text, record) => {
          return (
            <Row>
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                defaultChecked={text > 0}
                onChange={(value) => {
                  this.props.setActiveFlag(record.id, value);
                }}
              />
            </Row>
          );
        },
      },
      {
        key: "name",
        title: "Name",
        dataIndex: "name",
        width: 200,
      },
      {
        key: "sku",
        title: "SKU",
        dataIndex: "sku",
        width: 200,
      },
      {
        key: "door_style_name",
        title: "Door Style",
        dataIndex: "door_style_name",
        width: 200,
        render: (text, record) => (
          <Select
            mode="multiple"
            disabled={true}
            value={
              record.door_style_id === ""
                ? []
                : record.door_style_id.split(",").map((obj) => parseInt(obj))
            }
            dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: "auto" }}
            style={{ width: "100%" }}
          >
            {styleOptions()}
          </Select>
        ),
      },
      {
        key: "collection_name",
        title: "Collection",
        dataIndex: "collection_name",
        width: 200,
      },
      {
        key: "thumbnail",
        title: "Thumbnail",
        dataIndex: "thumbnail",
        colSpan: 2,
        width: 400,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={text}>
            <span>{text.slice(0, 40) + "..."}</span>
          </Tooltip>
        ),
      },
      {
        key: "thumbnailPreview",
        dataIndex: "thumbnail",
        colSpan: 0,
        width: 150,
        render: (text, record) => (
          <Row className="list__thumbnail">
            <img src={text} alt="" />
          </Row>
        ),
      },
      {
        key: "texture",
        title: "Texture",
        dataIndex: "texture",
        colSpan: 2,
        width: 400,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={text}>
            <span>{text === "" ? "Empty" : text.slice(0, 40) + "..."}</span>
          </Tooltip>
        ),
      },
      {
        key: "texturePreview",
        dataIndex: "texture",
        colSpan: 0,
        width: 150,
        render: (text, record) => {
          return text !== "" ? (
            <Row className="list__thumbnail">
              <img src={text} alt="" />
            </Row>
          ) : (
            ""
          );
        },
      },
      {
        key: "color",
        dataIndex: "color",
        title: "Color",
        width: 150,
        render: (text, record) => (
          <Row gutter={12}>
            <Col span={4}>
              <span
                className="rc-color-picker-trigger"
                style={{ backgroundColor: text }}
              ></span>
            </Col>
            <Col span={20}>
              <span>{text}</span>
            </Col>
          </Row>
        ),
      },
      {
        key: "color_family",
        dataIndex: "color_family_id",
        title: "Color Famiy",
        width: 300,
        render: (text, record) => (
          <Select
            mode="multiple"
            disabled={true}
            value={
              record.color_family_id === ""
                ? []
                : record.color_family_id.split(",").map((obj) => parseInt(obj))
            }
            dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: "auto" }}
            style={{ width: "100%" }}
          >
            {colordata}
          </Select>
        ),
      },
      {
        key: "glossness",
        dataIndex: "glossness",
        title: "Glossness",
        width: 150,
        render: (text, record) => (
          <Row gutter={12}>
            <Col span={24}>
              <span>{text}</span>
            </Col>
          </Row>
        ),
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "id",
        width: 250,
        fixed: "right",
        render: (text, record) => (
          <Row>
            <IconButton
              className={classes.button}
              aria-label="ArrowUp"
              onClick={this.moveAction("up", record)}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="ArrowDown"
              onClick={this.moveAction("down", record)}
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Delete"
              onClick={this.toggleDrawer("edit", record, true)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Edit"
              onClick={(e) => {
                e.preventDefault();
                this.handleDeleteEvent(record);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Row>
        ),
      },
    ];

    return (
      <Spin tip="Loading..." spinning={isLoading}>
        <FusePageCarded
          classes={{
            toolbar: "p-0",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
          }}
          header={
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Title
                        level={4}
                        className="text-16 sm:text-20 truncate"
                      ></Title>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  onClick={this.toggleDrawer("new", null, true)}
                >
                  Add
                </Button>
              </FuseAnimate>
            </div>
          }
          contentToolbar={
            <Title
              level={4}
              style={{ marginLeft: "25px" }}
              className="text-16 sm:text-20 truncate"
              classes={{ root: "w-full h-64" }}
            >
              {"Door Color"}
            </Title>
          }
          content={
            <div className="p-24">
              <Row>
                <Drawer
                  anchor="right"
                  open={visible}
                  variant="temporary"
                  onClose={this.toggleDrawer(null, null, false)}
                  classes={{ paper: classes.list }}
                  onRendered={() => {
                    setFieldsValue({ ...this.state.fieldData });
                  }}
                >
                  <div tabIndex={0} role="button">
                    <Title
                      level={3}
                      className="pt-16"
                      style={{ textAlign: "center" }}
                    >
                      {type === "new" ? "Add" : "Edit"} Door Color
                    </Title>
                    <Divider />
                    <Form layout="vertical" style={{ marginTop: "20px" }}>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="colorName"
                            {...formItemLayout}
                            label="Name"
                          >
                            {getFieldDecorator("name", {
                              initialValue: fieldData.name,
                              rules: [
                                {
                                  required: true,
                                  message: "Enter Door Color Name!",
                                },
                              ],
                            })(
                              <Input
                                placeholder="Enter Door Color Name"
                                onChange={(e) => {
                                  this.setState({
                                    fieldData: {
                                      ...fieldData,
                                      name: e.target.value,
                                    },
                                  });
                                }}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem key="sku" {...formItemLayout} label="SKU">
                            {getFieldDecorator("sku", {
                              initialValue: fieldData.sku,
                            })(
                              <Input
                                placeholder="Enter SKU Number"
                                onChange={(e) => {
                                  this.setState({
                                    fieldData: {
                                      ...fieldData,
                                      sku: e.target.value,
                                    },
                                  });
                                }}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="doorStyleId"
                            {...formItemLayout}
                            label="Door Style"
                          >
                            {getFieldDecorator("door_style_id", {
                              initialValue: fieldData.door_style_id,
                              rules: [
                                {
                                  required: true,
                                  message: "Please select Door Style!",
                                },
                              ],
                            })(
                              <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                showSearch
                                dropdownStyle={{
                                  zIndex: 10000,
                                  maxHeight: 400,
                                  overflow: "auto",
                                }}
                                placeholder="Please select"
                                onSelect={(value) => {
                                  this.setState({
                                    fieldData: {
                                      ...fieldData,
                                      door_style_id: value,
                                    },
                                  });
                                }}
                              >
                                {styleOptions()}
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="collection_id"
                            {...formItemLayout}
                            label="Collection Name"
                          >
                            {getFieldDecorator("collection_id", {
                              initialValue: fieldData.collection_id,
                              rules: [
                                {
                                  required: true,
                                  message: "Please select your Collection!",
                                },
                              ],
                            })(
                              <Select
                                dropdownStyle={{
                                  zIndex: 10000,
                                  maxHeight: 400,
                                  overflow: "auto",
                                }}
                                placeholder="Please select your collection"
                                onChange={(value) => {
                                  this.setState({
                                    fieldData: {
                                      ...fieldData,
                                      collection_id: value,
                                    },
                                  });
                                }}
                              >
                                {menuItems}
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="color_family_id"
                            {...formItemLayout}
                            label="Door Color Family"
                          >
                            {getFieldDecorator("color_family_id", {
                              initialValue: fieldData.color_family_id,
                              rules: [
                                {
                                  required: true,
                                  message:
                                    "Please select your Door Color Family",
                                },
                              ],
                            })(
                              <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                showSearch
                                dropdownStyle={{
                                  zIndex: 10000,
                                  maxHeight: 400,
                                  overflow: "auto",
                                }}
                                placeholder="Please select"
                                onSelect={(value) => {
                                  this.setState({
                                    fieldData: {
                                      ...fieldData,
                                      color_family_id: value,
                                    },
                                  });
                                }}
                              >
                                {colordata}
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="thumbnail"
                            {...formItemLayout}
                            label="Thumbnail"
                          >
                            {getFieldDecorator("thumbnail", {
                              initialValue: fieldData.thumbnail,
                              valuePropName: "fileList",
                              rules: [
                                {
                                  required: true,
                                  message: "Please upload Thumbnail!",
                                },
                              ],
                              getValueFromEvent: this.normFile,
                            })(
                              <Upload
                                listType="picture-card"
                                className="upload-list-inline"
                                onRemove={(file) => {
                                  this.setState({
                                    fieldData: { ...fieldData, thumbnail: [] },
                                  });
                                }}
                                beforeUpload={(file) => {
                                  this.setState({
                                    fieldData: {
                                      ...fieldData,
                                      thumbnail: [file],
                                    },
                                  });
                                  return false;
                                }}
                              >
                                {getFieldValue("thumbnail").length > 0
                                  ? null
                                  : uploadButton}
                              </Upload>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="texture"
                            {...formItemLayout}
                            label="Texture"
                          >
                            {getFieldDecorator("texture", {
                              initialValue: fieldData.texture,
                              valuePropName: "fileList",
                              rules: [
                                {
                                  required: true,
                                  message: "Please upload Texture!",
                                },
                              ],
                              getValueFromEvent: this.normFile,
                            })(
                              <Upload
                                listType="picture-card"
                                className="upload-list-inline"
                                onRemove={(file) => {
                                  this.setState({
                                    fieldData: { ...fieldData, texture: [] },
                                  });
                                }}
                                beforeUpload={(file) => {
                                  this.setState({
                                    fieldData: {
                                      ...fieldData,
                                      texture: [file],
                                    },
                                  });
                                  return false;
                                }}
                              >
                                {getFieldValue("texture").length > 0
                                  ? null
                                  : uploadButton}
                              </Upload>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="colorValue"
                            {...formItemLayout}
                            label="Color"
                          >
                            {getFieldDecorator("color", {
                              initialValue: fieldData.color,
                            })(
                              <Input
                                placeholder="Enter Door Color"
                                disabled
                                addonBefore={
                                  <div id="colorPicker">
                                    <ColorPicker
                                      color={fieldData.color}
                                      getCalendarContainer={() => {
                                        return document.getElementById(
                                          "colorPicker"
                                        );
                                      }}
                                      onClose={(e) => {
                                        this.setState({
                                          fieldData: {
                                            ...fieldData,
                                            color: e.color,
                                          },
                                        });
                                        setFieldsValue({ color: e.color });
                                      }}
                                    />
                                  </div>
                                }
                                onChange={(e) => {
                                  this.setState({
                                    fieldData: {
                                      ...fieldData,
                                      color: e.target.value,
                                    },
                                  });
                                }}
                              />
                              //
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="glossuValue"
                            {...formItemLayout}
                            label="Glossness"
                          >
                            {getFieldDecorator("glossness", {
                              initialValue: fieldData.glossness,
                            })(
                              <InputNumber
                                min={0}
                                max={1}
                                step={0.02}
                                onChange={(value) => {
                                  this.setState({
                                    fieldData: {
                                      ...fieldData,
                                      glossness: value,
                                    },
                                  });
                                }}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Form>
                    <div
                      style={{
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        borderTop: "1px solid #e9e9e9",
                        padding: "10px 16px",
                        background: "#fff",
                        textAlign: "right",
                      }}
                    >
                      <Button
                        onClick={this.toggleDrawer(null, null, false)}
                        style={{ marginRight: 8 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        // disabled={!this.canBeSubmitted()}
                      >
                        {type === "new" ? "ADD" : "UPDATE"}
                      </Button>
                    </div>
                  </div>
                </Drawer>
                <Table
                  bordered
                  rowKey="id"
                  className="mtable"
                  pagination={defaultPagination}
                  columns={columns}
                  dataSource={doorColor}
                  scroll={{ x: 2300, y: 500 }}
                />
              </Row>
            </div>
          }
        />
      </Spin>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getConstData: Actions.getConstData,
      addDoorColor: Actions.addDoorColor,
      deleteDoorColor: Actions.deleteDoorColor,
      updateDoorColor: Actions.updateDoorColor,
      showMessage: appActions.showMessage,
      setPublishedFlag: Actions.setPublishedFlag,
      setActiveFlag: Actions.setActiveFlag,
      setSortOrder: Actions.setSortOrder,
    },
    dispatch
  );
}

function mapStateToProps({ doorColorApp, fuse }) {
  return {
    doorColor: doorColorApp.doorColor.doorColor,
    doorStyle: doorColorApp.doorColor.doorStyle,
    collectionData: doorColorApp.doorColor.collectionData,
    isLoading: fuse.loading.isLoading,
    doorColorFamilyData: doorColorApp.doorColor.doorColorFamilyData,
  };
}
const DoorColorComponent = Form.create()(DoorColor);

export default withReducer(
  "doorColorApp",
  reducer
)(
  withStyles(styles, { withTheme: true })(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(DoorColorComponent))
  )
);

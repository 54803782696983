import React, { Component } from "react";
import * as Actions from "./store/actions";
import { withRouter } from "react-router-dom";
import withReducer from "app/store/withReducer";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import reducer from "./store/reducers";
import {
  IconButton,
  Button,
  Drawer,
  Divider,
  MenuItem,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Table, Modal, Typography } from "antd";
import Formsy from "formsy-react";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import { FusePageCarded, FuseAnimate } from "@fuse";
import _ from "@lodash";
import axios from "axios";
import { API_SERVER_URL } from "app/main/config";
import { showMessage } from "app/store/actions/fuse";
import { showAlert } from "./store/actions";

const { Title } = Typography;

const styles = (theme) => ({
  input: {
    display: "none",
  },
  addButton: {
    position: "absolute",
    right: 80,
    bottom: 70,
    zIndex: 99,
  },
  list: {
    width: 500,
  },
});

let newDealer = {
  id: "",
  companyName: "",
  contactPerson: "",
  address: "",
  telephone: "",
  visualizerName: "",
  url: "",
  email: "",
  fromEmail: "",
  bcc: "",
};

let defaultPagination = {
  pageSizeOptions: ["5", "10", "50", "250"],
  showSizeChanger: true,
  size: "small",
  position: "top",
};

const { confirm } = Modal;

class Dealer extends Component {
  state = {
    ...newDealer,
    dueMenu: null,
    canSubmit: false,
    checked: false,
    anchorEl: null,
    catalogData: [],
    catalog_id: null,
    password: "",
    password2: "",
    oldPassword: "",
  };
  componentDidMount() {
    this.props.getDealer();
    this.getCatalogData();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    /**
     * After Dialog Open
     */
    if (!prevProps.userDialog.props.open && this.props.userDialog.props.open) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.userDialog.type === "edit" &&
        this.props.userDialog.data &&
        !_.isEqual(this.props.userDialog.data, prevState)
      ) {
        this.setState({ ...this.props.userDialog.data });
      }

      /**
       * Dialog type: 'new'
       * Update State
       */
      if (
        this.props.userDialog.type === "new" &&
        !_.isEqual(newDealer, prevState)
      ) {
        this.setState({ ...newDealer });
      }
    }
  }
  getCatalogData() {
    axios
      .post(`${API_SERVER_URL}/api/catalog/read/catalog`)
      .then((res) => {
        const { catalogData, success } = res.data;
        if (success === true) {
          this.setState({ catalogData: catalogData });
        }
      })
      .catch((err) => {
        showMessage({
          message: "Failed to load catalog data",
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        });
        console.log("err", err);
      });
  }
  onSubmit = (model) => {
    if (this.props.userDialog.type === "new") {
      if (model.password === model.password2) {
        this.props.addDealer(model);
        this.props.closeNewUserDialog();
      } else {
        this.props.showAlert("Password must be matched", "error");
      }
    } else {
      const dealer = {
        id: this.props.userDialog.data.id,
        ...model,
      };
      if (model.password === model.password2) {
        this.props.updateDealer(dealer);
        this.props.closeEditUserDialog();
      } else {
        this.props.showAlert("Password must be matched", "error");
      }
    }
  };
  handleDeleteEvent = (record) => {
    const { deleteDealer } = this.props;
    confirm({
      title: "Do you want to delete this Item?",
      onOk() {
        deleteDealer(record.id);
      },
      onCancel() {},
    });
  };
  closeComposeDialog = () => {
    this.props.userDialog.type === "edit"
      ? this.props.closeEditUserDialog()
      : this.props.closeNewUserDialog();
    this.setState({ checked: false });
  };
  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };
  render() {
    const {
      classes,
      openNewUserDialog,
      dealer,
      userDialog,
      openEditUserDialog,
    } = this.props;
    const { canSubmit } = this.state;
    const data = dealer.map((obj, index) => ({
      ...obj,
      key: index,
    }));
    const columns = [
      {
        title: "Company Name",
        dataIndex: "companyName",
        key: "companyName",
        width: 250,
      },
      {
        title: "ContactPerson",
        dataIndex: "contactPerson",
        key: "contactPerson",
        width: 250,
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        width: 250,
      },
      {
        title: "Telephone",
        dataIndex: "telephone",
        key: "telephone",
        width: 250,
      },
      {
        title: "VisualizerName",
        dataIndex: "visualizerName",
        key: "visualizerName",
        width: 250,
      },
      {
        title: "Url",
        dataIndex: "url",
        key: "url",
        width: 250,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 250,
      },
      {
        title: "FromEmail",
        dataIndex: "fromEmail",
        key: "fromEmail",
        width: 250,
      },
      {
        title: "BCC",
        dataIndex: "bcc",
        key: "bcc",
        width: 250,
      },
      {
        title: "Catalog Name",
        dataIndex: "catalog_name",
        key: "catalog_name",
        width: 250,
      },
      {
        title: "Action",
        dataIndex: "id",
        width: 150,
        key: "action",
        fixed: "right",
        render: (text, record) => (
          <div>
            <IconButton
              className={classes.button}
              aria-label="Edit"
              onClick={(e) => {
                openEditUserDialog(record);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Delete"
              onClick={(e) => {
                e.preventDefault();
                this.handleDeleteEvent(record);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    ];
    const catalogItems = this.state.catalogData.map((fElement) => {
      return (
        <MenuItem key={fElement.id} value={fElement.id}>
          {fElement.name}
        </MenuItem>
      );
    });
    return (
      <React.Fragment>
        <FusePageCarded
          classes={{
            toolbar: "p-0",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
          }}
          header={
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Title
                        level={4}
                        className="text-16 sm:text-20 truncate"
                      ></Title>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  onClick={openNewUserDialog}
                >
                  ADD
                </Button>
              </FuseAnimate>
            </div>
          }
          contentToolbar={
            <Title
              level={4}
              style={{ marginLeft: "25px" }}
              className="text-16 sm:text-20 truncate"
              classes={{ root: "w-full h-64" }}
            >
              {"Dealer"}
            </Title>
          }
          content={
            <div className="p-24">
              <Drawer
                anchor="right"
                {...userDialog.props}
                variant="temporary"
                onClose={this.closeComposeDialog}
                classes={{ paper: classes.list }}
              >
                <div tabIndex={0} role="button">
                  <Title
                    level={3}
                    className="pt-16"
                    style={{ textAlign: "center" }}
                  >
                    {userDialog.type === "new" ? "Add" : "Edit"} Dealer
                  </Title>
                  <Divider />

                  <Formsy
                    className="m-20"
                    onValidSubmit={this.onSubmit}
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    ref={(form) => (this.form = form)}
                  >
                    <TextFieldFormsy
                      className="w-full mb-24"
                      type="text"
                      name="companyName"
                      label="Company Name"
                      validations={{
                        minLength: 1,
                      }}
                      variant="outlined"
                      required
                      value={this.state.companyName}
                    />
                    <TextFieldFormsy
                      className="w-full mb-24"
                      type="text"
                      name="contactPerson"
                      label="Contact Person"
                      validations={{
                        minLength: 1,
                      }}
                      variant="outlined"
                      required
                      value={this.state.contactPerson}
                    />
                    <TextFieldFormsy
                      className="w-full mb-24"
                      type="text"
                      name="address"
                      label="Address"
                      validations={{
                        minLength: 1,
                      }}
                      variant="outlined"
                      required
                      value={this.state.address}
                    />
                    <TextFieldFormsy
                      className="w-full mb-24"
                      type="text"
                      name="telephone"
                      label="Telephone"
                      validations={{
                        minLength: 1,
                      }}
                      variant="outlined"
                      required
                      value={this.state.telephone}
                    />
                    <TextFieldFormsy
                      className="w-full mb-24"
                      type="text"
                      name="visualizerName"
                      label="Visualizer Name"
                      validations={{
                        minLength: 1,
                      }}
                      variant="outlined"
                      required
                      value={this.state.visualizerName}
                    />
                    <TextFieldFormsy
                      className="w-full mb-24"
                      type="text"
                      name="url"
                      label="URL"
                      validations={{
                        minLength: 1,
                      }}
                      variant="outlined"
                      required
                      value={this.state.url}
                    />
                    <TextFieldFormsy
                      className="w-full mb-24"
                      type="email"
                      name="email"
                      label="Email"
                      validations={{
                        minLength: 1,
                      }}
                      variant="outlined"
                      required
                      value={this.state.email}
                    />
                    <TextFieldFormsy
                      className="w-full mb-24"
                      type="text"
                      name="fromEmail"
                      label="From Email"
                      validations={{
                        minLength: 1,
                      }}
                      variant="outlined"
                      required
                      value={this.state.fromEmail}
                    />
                    <TextFieldFormsy
                      className="w-full mb-24"
                      type="text"
                      name="bcc"
                      label="BCC"
                      validations={{
                        minLength: 1,
                      }}
                      variant="outlined"
                      required
                      value={this.state.bcc}
                    />
                    {this.props.userDialog.type === "edit" ? (
                      <TextFieldFormsy
                        className="w-full mb-24"
                        type="password"
                        name="oldPassword"
                        label="Old Password"
                        validations={{
                          minLength: 1,
                        }}
                        variant="outlined"
                        value={this.state.oldPassword}
                      />
                    ) : (
                      ""
                    )}
                    <TextFieldFormsy
                      className="w-full mb-24"
                      type="password"
                      name="password"
                      label="Password"
                      validations={{
                        minLength: 1,
                      }}
                      variant="outlined"
                      required={this.props.userDialog.type === "new"}
                      value={this.state.password}
                    />
                    <TextFieldFormsy
                      className="w-full mb-24"
                      type="password"
                      name="password2"
                      label="Confirm Password"
                      validations={{
                        minLength: 1,
                      }}
                      variant="outlined"
                      required={this.props.userDialog.type === "new"}
                      value={this.state.password2}
                    />
                    <SelectFormsy
                      className="w-full mb-36"
                      name="catalog_id"
                      label="Catalog"
                      required
                      variant="outlined"
                      //multiple
                      value={
                        userDialog.type === "new" ? [] : this.state.catalog_id
                      }
                      onChange={(e) => {
                        this.setState({ catalog_id: e.target.value });
                      }}
                    >
                      {catalogItems}
                    </SelectFormsy>
                    <div
                      style={{
                        position: "relative",
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        borderTop: "1px solid #e9e9e9",
                        padding: "10px 16px",
                        background: "#fff",
                        textAlign: "right",
                      }}
                    >
                      <Button
                        onClick={this.closeComposeDialog}
                        style={{ marginRight: 8 }}
                      >
                        Cancel
                      </Button>
                      {userDialog.type === "new" ? (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          aria-label="REGISTER"
                          disabled={!canSubmit}
                          value="legacy"
                        >
                          ADD
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          aria-label="REGISTER"
                          disabled={!canSubmit}
                          value="legacy"
                        >
                          UPDATE
                        </Button>
                      )}
                    </div>
                  </Formsy>
                </div>
              </Drawer>
              <Table
                bordered
                className="mtable"
                columns={columns}
                pagination={defaultPagination}
                scroll={{ x: 2300, y: 500 }}
                dataSource={data}
              />
            </div>
          }
        />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDealer: Actions.getDealer,
      addDealer: Actions.addDealer,
      updateDealer: Actions.updateDealer,
      deleteDealer: Actions.deleteDealer,
      openNewUserDialog: Actions.openNewUserDialog,
      openEditUserDialog: Actions.openEditUserDialog,
      closeEditUserDialog: Actions.closeEditUserDialog,
      closeNewUserDialog: Actions.closeNewUserDialog,
      showAlert: Actions.showAlert,
    },
    dispatch
  );
}

function mapStateToProps({ dealer }) {
  return {
    dealer: dealer.dealer.dealerData,
    userDialog: dealer.dealer.userDialog,
  };
}

export default withReducer(
  "dealer",
  reducer
)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withStyles(styles, { withTheme: true })(Dealer))
  )
);

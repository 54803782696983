import axios from "axios";
import { showMessage } from "app/store/actions/fuse";
import { API_SERVER_URL, deleteFiles } from "app/main/config";
import { uploadLoading } from "app/store/actions/fuse";
import moment from "moment";

export const GET_PROJECT = "[PROJECT] GET PROJECT";
export function getProject(sort, filter) {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const pid = user.role === "designer" ? user.id : null;
    const access_token = window.localStorage.getItem("jwt_access_token");
    axios
      .post(`${API_SERVER_URL}/api/project/loadProject`, {
        userID: pid,
        userrole: user.role,
        access_token: access_token,
        sort,
        filter,
      })
      .then((response) => {
        const { projectElement, designer } = response.data;
        projectElement.forEach((element) => {
          let save = moment(element.saved_at);
          let newSave = save.utcOffset(-240).format("YYYY-MM-DD HH:mm:ss");
          element.saved_at = newSave;
          let create = moment(element.created_at);
          let newCreate = create.utcOffset(-240).format("YYYY-MM-DD HH:mm:ss");
          element.created_at = newCreate;
        });
        dispatch({
          type: GET_PROJECT,
          project: projectElement,
          designer: designer,
        });
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to load PROJECT data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

export const ADD_PROJECT = "[PROJECT] ADD PROJECT";
export const DELETE_PROJECT = "[PROJECT] DELETE PROJECT";
export function deleteProject(record) {
  return (dispatch) => {
    var p1 = new Promise((resolve, reject) => {
      dispatch(uploadLoading(true));
      resolve("success");
    });
    p1.then((p1Value) => {
      axios
        .post(`${API_SERVER_URL}/api/project/deleteProject`, {
          id: record.id,
        })
        .then((response) => {
          const { success } = response.data;
          dispatch(uploadLoading(false));
          if (success === true) {
            dispatch(
              showMessage({
                message: "Successfully Deleted",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "success",
              })
            );
            Promise.all([
              dispatch({
                type: DELETE_PROJECT,
              }),
            ]).then(() => dispatch(getProject()));
          }
        })
        .catch((err) => {
          dispatch(uploadLoading(false));
          dispatch(
            showMessage({
              message: "Failed to delete PROJECT data",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
            })
          );

          console.log("err", err);
        });
    }).catch((err) => {
      dispatch(uploadLoading(false));
      dispatch(
        showMessage({
          message: "AWS S3 DELETE PROJECT ERROR",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
      return;
    });
  };
}

export const UPDATE_PROJECT = "[PROJECT] UPDATE PROJECT";
export function updateProject(user_ids, id, designerEmail) {
  var getArray = (data) => {
    var items = "";
    data.forEach((fElement, index) => {
      if (index === 0) items += "/" + fElement;
      else items += "/" + fElement;
    });
    items += "/";
    return items;
  };
  const data = getArray(user_ids.user_ids);
  return (dispatch) => {
    const request = axios.post(`${API_SERVER_URL}/api/project/update/project`, {
      data,
      id,
    });

    return request
      .then((response) => {
        dispatch(
          showMessage({
            message: "Successfully Updated",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
        Promise.all([
          dispatch({
            type: UPDATE_PROJECT,
          }),
        ]).then(() => dispatch(getProject()));

        window.setTimeout(() => {
          designerEmail.map((email) => {
            axios
              .post(`${API_SERVER_URL}/api/project/update/project/sendMail`, {
                id,
                email,
              })
              .then((resp) => {
                if (resp.data.success) {
                  dispatch(
                    showMessage({
                      message: "Mail successfully sended to designer",
                      autoHideDuration: 2000,
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      variant: "success",
                    })
                  );
                } else {
                  dispatch(
                    showMessage({
                      message: "Failed to send mail",
                      autoHideDuration: 2000,
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      variant: "error",
                    })
                  );
                }
              });
          });
        }, 2000);
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to update PROJECT data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );

        console.log("err", err);
      });
  };
}

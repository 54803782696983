import Furnishing from "./Furnishing";
import { authRoles } from "app/auth";
export const FurnishingConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/finishing_touch/furnishing",
      component: Furnishing,
    },
  ],
};

exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./normalize.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./print.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./tables.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./react-table.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./prism.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./tailwind.css"), "");

// Module
exports.push([module.id, "* {\n  outline: none !important;\n}\n\nhtml {\n  font-size: 62.5%;\n  font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;\n  background-color: #262933;\n}\n\nbody {\n  font-size: 14px;\n  line-height: 1.4;\n}\n\nhtml,\nbody,\n#root {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  position: relative;\n}\n\nh1,\n.h1 {\n  font-size: 24px;\n}\n\nh2,\n.h2 {\n  font-size: 20px;\n}\n\nh3,\n.h3 {\n  font-size: 16px;\n}\n\nh4,\n.h4 {\n  font-size: 15px;\n}\n\nh5,\n.h5 {\n  font-size: 13px;\n}\n\nh6,\n.h6 {\n  font-size: 12px;\n}\n\n.ps > .ps__rail-y,\n.ps > .ps__rail-x {\n  z-index: 99;\n}\n\na[role=\"button\"] {\n  text-decoration: none;\n}\n\n/* Medium Devices, Desktops Only */\n@media only screen and (min-width: 992px) {\n  ::-webkit-scrollbar {\n    width: 12px;\n    height: 12px;\n    background-color: rgba(0, 0, 0, 0);\n  }\n\n  ::-webkit-scrollbar:hover {\n    width: 12px;\n    height: 12px;\n    background-color: rgba(0, 0, 0, 0.06);\n  }\n\n  ::-webkit-scrollbar-thumb {\n    border: 2px solid transparent;\n    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);\n    border-radius: 20px;\n  }\n\n  ::-webkit-scrollbar-thumb:active {\n    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);\n    border-radius: 20px;\n  }\n}\n\nform label {\n  z-index: 99;\n}\n", ""]);


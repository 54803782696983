import Appliance from "./Appliance";
import { authRoles } from "app/auth";
export const ApplianceConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/appliance/appliance",
      component: Appliance,
    },
  ],
};

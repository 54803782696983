import axios from "axios";
import { showMessage } from "app/store/actions/fuse";
import {
  API_SERVER_URL,
  jsonArray,
  uploadFiles,
  putFiles,
  deleteFiles,
} from "app/main/config";
import { uploadLoading } from "app/store/actions/fuse";

export const GET_DOOR_STYLE = "[DOOR_STYLE] GET DOOR STYLE";
export function getDoorStyle() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/doorStyle/read/door_style`)
      .then((response) => {
        const { doorStyle } = response.data;
        dispatch({
          type: GET_DOOR_STYLE,
          doorStyle: doorStyle,
        });
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to load DOOR STYLE data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
      });
  };
}

var getData = (data, index) => {
  var result = [];
  var tmpArray = data.filter(
    (element) => element.parent_door_category_id === index
  );
  tmpArray.forEach((iElement) => {
    if (
      data.some((element) => element.parent_door_category_id === iElement.id)
    ) {
      iElement.children = getData(data, iElement.id);
      result.push({
        ...iElement,
      });
    } else {
      result.push({
        ...iElement,
      });
    }
  });
  result.sort(function (a, b) {
    return a.sort_order - b.sort_order;
  });
  return result;
};

export const GET_DOOR_CATEGORY = "[DOOR_STYLE] GET DOOR CATEGORY";
export function getDoorCategory() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/doorCategory/read/door_category`)
      .then((response) => {
        const { doorCategory, success } = response.data;
        if (success === true) {
          var result = getData(doorCategory, 0);

          dispatch({
            type: GET_DOOR_CATEGORY,
            doorCategory: result,
          });
        } else {
          Promise.all([
            dispatch({
              type: GET_DOOR_STYLE,
            }),
          ]).then(() => dispatch(getDoorStyle()));
        }
      });
  };
}

export const ADD_DOOR_STYLE = "[DOOR_STYLE] ADD DOOR STYLE";
export function addDoorStyle(data) {
  var newData = {
    door_category_id: data.door_category_id,
    name: data.door_style_name,
    thumbnail: data.thumbnail[0].name,
  };
  const formData = new FormData();
  formData.append("file", data.thumbnail[0].originFileObj);

  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/doorStyle/insert/door_style`, {
        ...newData,
      })
      .then((res) => {
        dispatch(uploadLoading(true));
        if (process.env.REACT_APP_MODE === "production") {
          uploadFiles(
            data.thumbnail.filter((file) => file.originFileObj !== undefined),
            `uploads/DoorStyle/${
              res.data.doorStyle.guid + "_" + res.data.doorStyle.id
            }/`
          )
            .then((thumbnail) => {
              const uploadData = [
                {
                  fieldName: "thumbnail",
                  type: "single",
                  data: thumbnail,
                },
              ];
              axios
                .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                  id: res.data.doorStyle.id,
                  tableName: "door_style",
                  data: uploadData,
                })
                .then((response) => {
                  const { success } = response.data;
                  dispatch(uploadLoading(false));
                  if (success === true) {
                    dispatch(
                      showMessage({
                        message: "Successfully Inserted",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        variant: "success",
                      })
                    );
                    Promise.all([
                      dispatch({
                        type: ADD_DOOR_STYLE,
                      }),
                    ]).then(() => dispatch(getDoorStyle()));
                  } else {
                    console.log(success);
                  }
                });
            })
            .catch((err) => {
              console.log("failed to upload door style thumbnail. ", err);
              return;
            });
        } else {
          formData.append("guid", res.data.doorStyle.guid);
          formData.append("id", res.data.doorStyle.id);
          axios
            .post(`${API_SERVER_URL}/api/doorStyle/upload`, formData)
            .then((response) => {
              const { success } = response.data;
              dispatch(uploadLoading(false));
              if (success === true) {
                axios.post(`${API_SERVER_URL}/uploads/clear`).then((res) => {
                  if (res.data.success === true) {
                    dispatch(
                      showMessage({
                        message: "Successfully Inserted",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        variant: "success",
                      })
                    );
                    Promise.all([
                      dispatch({
                        type: ADD_DOOR_STYLE,
                      }),
                    ]).then(() => dispatch(getDoorStyle()));
                  }
                });
              }
            })
            .catch((err) => {
              dispatch(
                showMessage({
                  message: "Failed to add DOOR STYLE data",
                  autoHideDuration: 2000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  variant: "error",
                })
              );

              console.log("err", err);
            });
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to upload data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );

        console.log("err", err);
      });
  };
}

export const DELETE_DOOR_STYLE = "[DOOR_STYLE] DELETE DOOR STYLE";
export function deleteDoorStyle(record) {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/doorStyle/remove/door_style`, {
        id: record.id,
      })
      .then((response) => {
        const { success } = response.data;
        if (success === true) {
          var p1 = new Promise((resolve, reject) => {
            dispatch(uploadLoading(true));
            if (process.env.REACT_APP_MODE === "production") {
              const data = [];
              data.push(record.thumbnail);
              deleteFiles(data)
                .then((res) => {
                  resolve("success");
                })
                .catch((err) => {
                  console.log(err);
                  reject(err);
                });
            } else {
              resolve("success");
            }
          });
          p1.then((p1Value) => {
            dispatch(uploadLoading(false));
            dispatch(
              showMessage({
                message: "Successfully Deleted",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "success",
              })
            );

            Promise.all([
              dispatch({
                type: DELETE_DOOR_STYLE,
              }),
            ]).then(() => dispatch(getDoorStyle()));
          }).catch((err) => {
            dispatch(uploadLoading(false));
            dispatch(
              showMessage({
                message: "AWS S3 DELETE DOOR_STYLE ERROR",
                autoHideDuration: 4000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "error",
              })
            );
            return;
          });
        } else {
          dispatch(
            showMessage({
              message: "Some Door Colors are using this style",
              autoHideDuration: 4000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "warning",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(uploadLoading(false));
        dispatch(
          showMessage({
            message: "Failed to delete DOOR STYLE data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

export const UPDATE_DOOR_STYLE = "[DOOR_STYLE] UPDATE DOOR STYLE";
export function updateDoorStyle(data, id, guid) {
  var updateData = {
    id: id,
    door_category_id: data.door_category_id,
    name: data.door_style_name,
    thumbnail: jsonArray([data.thumbnail[0]]),
  };

  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/doorStyle/update/door_style`, {
        ...updateData,
      })
      .then((res) => {
        dispatch(uploadLoading(true));
        if (process.env.REACT_APP_MODE === "production") {
          putFiles(
            data.thumbnail.filter((file) => file.originFileObj !== undefined),
            `uploads/DoorStyle/${guid + "_" + id}/`
          )
            .then((thumbnail) => {
              const uploadData = [
                {
                  fieldName: "thumbnail",
                  type: "single",
                  data: thumbnail,
                },
              ];

              axios
                .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                  id: id,
                  tableName: "door_style",
                  data: uploadData,
                })
                .then((response) => {
                  const { success } = response.data;
                  dispatch(uploadLoading(false));
                  if (success === true) {
                    dispatch(
                      showMessage({
                        message: "Successfully Updated",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        variant: "success",
                      })
                    );
                    Promise.all([
                      dispatch({
                        type: UPDATE_DOOR_STYLE,
                      }),
                    ]).then(() => dispatch(getDoorStyle()));
                  } else {
                    console.log(success);
                  }
                });
            })
            .catch((err) => {
              console.log("failed to put door style thumbnail. ", err);
              return;
            });
        } else {
          if (data.thumbnail[0].originFileObj !== undefined) {
            const formData = new FormData();
            formData.append("file", data.thumbnail[0].originFileObj);
            formData.append("guid", guid);
            formData.append("id", id);
            axios
              .post(`${API_SERVER_URL}/api/doorStyle/upload`, formData)
              .then((response) => {
                const { success } = response.data;
                dispatch(uploadLoading(false));
                if (success === true) {
                  axios.post(`${API_SERVER_URL}/uploads/clear`).then((res) => {
                    if (res.data.success === true) {
                      dispatch(
                        showMessage({
                          message: "Successfully Updated",
                          autoHideDuration: 2000,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          variant: "success",
                        })
                      );
                      Promise.all([
                        dispatch({
                          type: UPDATE_DOOR_STYLE,
                        }),
                      ]).then(() => dispatch(getDoorStyle()));
                    }
                  });
                }
              });
          } else {
            dispatch(uploadLoading(false));
            dispatch(
              showMessage({
                message: "Successfully Updated",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "success",
              })
            );
            Promise.all([
              dispatch({
                type: UPDATE_DOOR_STYLE,
              }),
            ]).then(() => dispatch(getDoorStyle()));
          }
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to update DOOR STYLE data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );

        console.log("err", err);
      });
  };
}

export const SET_DOOR_STYLE_SORT_ORDER = "[DOOR_STYLE] SET SORT ORDER";
export function setSortOrder(data) {
  return (dispatch) => {
    dispatch(uploadLoading(true));
    axios
      .post(`${API_SERVER_URL}/api/base/setSortOrder`, {
        id: data.id,
        sort_order: data.sort_order,
        table_name: "door_style",
      })
      .then((response) => {
        const { success } = response.data;
        if (success === true) {
          dispatch(uploadLoading(false));
          Promise.all([
            dispatch({
              type: SET_DOOR_STYLE_SORT_ORDER,
            }),
          ]).then(() => dispatch(getDoorStyle()));
        } else {
          dispatch(uploadLoading(false));
        }
      })
      .catch((err) => {
        dispatch(uploadLoading(false));
        dispatch(
          showMessage({
            message: "Failed to set sort order",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("failed to set sort order in door style.", err);
      });
  };
}

import DoorStyle from "./DoorStyle";
import { authRoles } from "app/auth";

export const DoorStyleConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/door/style",
      component: DoorStyle,
    },
  ],
};

import Collection from "./Collection";
import { authRoles } from "app/auth";
export const CollectionConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/Collection",
      component: Collection,
    },
  ],
};

import * as Actions from "../actions";

const initialState = {
  catalogData: [],
  subgroupData: [],
  colorAliasData: [],
  manufacturer_subgroup_ids: [],
  userDialog: {
    type: "new",
    props: {
      open: false,
    },
    data: null,
  },
};

const catalogReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CATALOG: {
      return {
        ...state,
        catalogData: action.payload.catalogData,
        subgroupData: action.payload.subgroupData,
        colorAliasData: action.payload.colorAliasData,
      };
    }
    case Actions.DELETE_CATALOG: {
      return {
        ...state,
        catalogData: action.payload,
      };
    }
    case Actions.OPEN_NEW_USER_DIALOG: {
      return {
        ...state,
        userDialog: {
          type: "new",
          props: {
            open: true,
          },
          data: null,
        },
      };
    }
    case Actions.CLOSE_NEW_USER_DIALOG: {
      return {
        ...state,
        userDialog: {
          type: "new",
          props: {
            open: false,
          },
          data: null,
        },
      };
    }
    case Actions.OPEN_EDIT_USER_DIALOG: {
      return {
        ...state,
        userDialog: {
          type: "edit",
          props: {
            open: true,
          },
          data: action.data,
        },
      };
    }
    case Actions.CLOSE_EDIT_USER_DIALOG: {
      return {
        ...state,
        userDialog: {
          type: "edit",
          props: {
            open: false,
          },
          data: null,
        },
      };
    }

    case Actions.GET_MANUFACTURER: {
      return {
        ...state,
        manufacturerData: action.payload.manufacturer,
        color_familys: action.payload.color_familys,
      };
    }
    case Actions.ADD_MANUFACTURER: {
      return {
        ...state,
        manufacturer_subgroup_ids: [
          ...state.manufacturer_subgroup_ids,
          action.payload,
        ],
      };
    }
    case Actions.SET_MANUFACTURER: {
      return {
        ...state,
        manufacturer_subgroup_ids: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default catalogReducer;

import CabinetCategory from "./CabinetCategory";
import { authRoles } from "app/auth";
export const CabinetCategoryConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/cabinet/category",
      component: CabinetCategory,
    },
  ],
};

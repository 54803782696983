import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "@fuse";
import { ExampleConfig } from "app/main/example/ExampleConfig";
import { DoorColorConfig } from "app/main/door_color/DoorColorConfig";
import { DoorCategoryConfig } from "app/main/door_category/DoorCategoryConfig";
import { ApplianceCategoryConfig } from "app/main/appliance_category/ApplianceCategoryConfig";
import { ManufacturerConfig } from "app/main/manufacturer/ManufacturerConfig";
import { CollectionConfig } from "app/main/collection/CollectionConfig";
import { DoorStyleConfig } from "app/main/door_style/DoorStyleConfig";
import { CabinetCategoryConfig } from "app/main/cabinet_category/CabinetCategoryConfig";
import { DoorHandleConfig } from "app/main/door_handle/DoorHandleConfig";
import { WallColorConfig } from "app/main/wall_color/WallColorConfig";
import { FloorStylesConfig } from "app/main/floor_styles/FloorStylesConfig";
import { BacksplashConfig } from "app/main/backsplash/BacksplashConfig";
import { FurnishingConfig } from "app/main/furnishing/FurnishingConfig";
import { LightingConfig } from "app/main/lighting/LightingConfig";
import { CounterTopConfig } from "app/main/counter_top/CounterTopConfig";
import { CabinetConfig } from "app/main/cabinet/CabinetConfig";
import { RTALiveConfig } from "app/main/rtalive/RTALiveConfig";
import { ProjectConfig } from "app/main/project/ProjectConfig";
import { ApplianceConfig } from "app/main/appliance/ApplianceConfig";
import { UpdateConfig } from "app/main/update/UpdateConfig";
import { DoorColorFamilyConfig } from "app/main/door_color_family/DoorColorFamilyConfig";

// import {CabinetDoorStyleConfig} from 'app/main/cabinet_door_style/CabinetDoorStyleConfig';
import { LoginConfig } from "app/main/login/LoginConfig";
import { UsersAppConfig } from "app/main/user/UsersAppConfig";
import { CatalogConfig } from "app/main/catalog/CatalogConfig";
import { DealerConfig } from "app/main/dealer/DealerConfig";

const routeConfigs = [
  ExampleConfig,
  DoorCategoryConfig,
  CatalogConfig,
  DealerConfig,
  ManufacturerConfig,
  CollectionConfig,
  DoorStyleConfig,
  DoorColorConfig,
  CabinetCategoryConfig,
  DoorHandleConfig,
  BacksplashConfig,
  WallColorConfig,
  FloorStylesConfig,
  LightingConfig,
  FurnishingConfig,
  CabinetConfig,
  RTALiveConfig,
  ApplianceCategoryConfig,
  LoginConfig,
  ApplianceConfig,
  CounterTopConfig,
  UsersAppConfig,
  ProjectConfig,
  UpdateConfig,
  DoorColorFamilyConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/cabinet/project" />,
  },
];

export default routes;

import Catalog from "./Catalog";
import { authRoles } from "app/auth";
export const CatalogConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/catalog",
      component: Catalog,
    },
  ],
};

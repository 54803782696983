import Backsplash from "./Backsplash";
import { authRoles } from "app/auth";
export const BacksplashConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/finishing_touch/backsplash",
      component: Backsplash,
    },
  ],
};

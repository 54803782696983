import React, { useEffect, useReducer, useState } from "react";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import Axios from "axios";
import { API_SERVER_URL } from "app/main/config";
import { bindActionCreators } from "redux";
import * as Actions from "app/store/actions";
import { connect } from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    position: "relative",
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  input: {
    transition: theme.transitions.create(["background-color"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
    "&:focus": {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

const initialState = {
  inProgress: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "default": {
      return {
        ...state,
        update: true,
      };
    }
    case "inProgress": {
      return {
        ...state,
        update: false,
      };
    }
  }
}

function UpdatePlanner(props) {
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");
  const classes = useStyles(props);

  useEffect(() => {
    Axios.post(`${API_SERVER_URL}/api/state/get_state`, {
      keyName: "lastUpdatedTime",
    })
      .then((res) => {
        setLastUpdatedTime(res.data[0].value);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  function updateData(props) {
    Axios.post(`${API_SERVER_URL}/api/state/updatePlannerAndCategroy`, {})
      .then((response) => {
        let msg = `Successfully started at ${moment(
          response.data.startTime
        ).format("YYYY-MM-DD HH:mm:ss")}`;
        if (response.data.state === "progress")
          msg = `In progress, started at ${moment(
            response.data.startTime
          ).format("YYYY-MM-DD HH:mm:ss")}.`;
        props.showMessage({
          message: msg,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        });
      })
      .catch((error) => {
        props.showMessage({ message: error });
      });
  }

  return (
    <>
      <div className={clsx(classes.root, "flex", props.className)}>
        <Tooltip title="Click to update data." placement="bottom">
          <div onClick={updateData.bind(null, props)}>{props.trigger}</div>
        </Tooltip>
      </div>
      <div className={clsx(classes.root, "flex", props.className)}>
        <div
          className="h-64"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            padding: "0px 16px 0px 0px",
            fontWeight: "bold",
            color: "#0000008A",
          }}
        >
          Last Updated : <br />
          {lastUpdatedTime
            ? moment(new Date(parseInt(lastUpdatedTime))).format(
                "YYYY-MM-DD HH:mm"
              )
            : ""}
        </div>
      </div>
    </>
  );
}

UpdatePlanner.propTypes = {};
UpdatePlanner.defaultProps = {
  trigger: (
    <IconButton className="w-64 h-64">
      <Icon>cached</Icon>
    </IconButton>
  ),
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMessage: Actions.showMessage,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(UpdatePlanner);

// export default withRouter(React.memo(UpdatePlanner));

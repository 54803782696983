import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoginForm from "./LoginForm";
import "./style.scss";

class Login extends Component {
  state = {
    backgroundImage: "url(assets/images/logos/1.jpg)",
    fullSize: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.login.success === true) {
      this.props.history.push("/manufacturer");
    }
  }

  generateBackground = () => {
    let { backgroundImage } = this.state;

    let min = 1;
    let max = 2;
    let picNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    backgroundImage = "url(assets/images/logos/" + picNumber + ".jpg)";
    this.setState({
      backgroundImage: backgroundImage,
    });
  };
  render() {
    const { backgroundImage } = this.state;

    return (
      <div
        className={"login login--fullscreen"}
        style={{ backgroundImage: backgroundImage }}
      >
        <div className="login__header">
          <div className="row">
            <div className="col-lg-8">
              <div className="login__header__logo">
                {/* <Button className="ml-3" onClick={this.generateBackground}>
                      Randomize Background Image
                    </Button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="login__block">
          <div className="row">
            <div className="col-xl-12">
              <div className="login__block__promo text-white text-center">
                <h1 className="mb-3 text-white">
                  <strong>WELCOME TO KITCHEN-CONFIGURATOR ADMIN</strong>
                </h1>
              </div>
              <div className="login__block__inner">
                <div className="login__block__form">
                  <h4 className="text-uppercase">
                    <strong>Please Log In</strong>
                  </h4>
                  <br />
                  <LoginForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    login: auth.login,
  };
}

export default withRouter(connect(mapStateToProps)(Login));

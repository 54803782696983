import ApplianceCategory from "./ApplianceCategory";
import { authRoles } from "app/auth";
export const ApplianceCategoryConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/appliance/category",
      component: ApplianceCategory,
    },
  ],
};

import CounterTop from "./CounterTop";
import { authRoles } from "app/auth";
export const CounterTopConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/counter/top",
      component: CounterTop,
    },
  ],
};

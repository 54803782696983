import Lighting from "./Lighting";
import { authRoles } from "app/auth";
export const LightingConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/finishing_touch/lighting",
      component: Lighting,
    },
  ],
};

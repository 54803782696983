import Update from "./Update";
import { authRoles } from "app/auth";
export const UpdateConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/update",
      component: Update,
    },
  ],
};

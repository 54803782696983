import React, { Component } from "react";
import { Button, Drawer, Divider } from "@material-ui/core";
import {
  Typography,
  Input,
  Upload,
  Form,
  Select,
  Row,
  Col,
  Checkbox,
} from "antd";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ReactSelect from "react-select";

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 },
};

const styles = (theme) => ({
  button: {
    marginTop: "-12px",
  },
  rightIcon: {
    marginLeft: "10px",
  },
  input: {
    display: "none",
  },
  list: {
    width: 700,
  },
});

class UnitInput extends React.Component {
  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ("value" in nextProps) {
      return {
        ...(nextProps.value || {}),
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    const value = props.value || {};
    this.state = {
      number: value.number || 0,
      currency: value.currency || "inch",
    };
  }

  handleNumberChange = (e) => {
    var number = e.target.value;

    if (!("value" in this.props)) {
      this.setState({ number });
    }
    this.triggerChange({ number });
  };

  handleCurrencyChange = (currency) => {
    if (!("value" in this.props)) {
      this.setState({ currency });
    }
    this.triggerChange({ currency });
  };

  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props;
    if (onChange) {
      onChange(Object.assign({}, this.state, changedValue));
    }
  };

  render() {
    const { size } = this.props;
    const { state } = this;
    return (
      <span>
        <Input
          type="number"
          size={size}
          value={state.number}
          onChange={this.handleNumberChange}
          style={{ width: "65%", marginRight: "3%" }}
          disabled={this.props.disabled}
        />
        <Select
          value={state.currency}
          size={size}
          style={{ width: "32%" }}
          onChange={this.handleCurrencyChange}
          disabled={this.props.disabled}
        >
          <Option value="inch">inch</Option>
          <Option value="cm">cm</Option>
        </Select>
      </span>
    );
  }
}

class CabinetDoorStyleDrawerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter_collection: [],
      filter_door_color: [],
      cds_select: {
        door: [[0], [1], [2], [3], [4], [5], [6], [7], [8], [9]],
        drawer: [[0], [1], [2], [3], [4], [5], [6], [7], [8], [9]],
        drawer_door: [[0], [1], [2], [3], [4], [5], [6], [7], [8], [9]],
        fixed_drawer_door: [[0], [1], [2], [3], [4], [5], [6], [7], [8], [9]],
      },
      euro_shape_svg: props.propsState.euroShape_svg || [],
    };
  }
  initiateState = () => {
    this.setState({
      filter_collection: [],
      filter_door_color: [],
      cds_select: {
        door: [[0], [1], [2], [3], [4], [5], [6], [7], [8], [9]],
        drawer: [[0], [1], [2], [3], [4], [5], [6], [7], [8], [9]],
        drawer_door: [[0], [1], [2], [3], [4], [5], [6], [7], [8], [9]],
        fixed_drawer_door: [[0], [1], [2], [3], [4], [5], [6], [7], [8], [9]],
      },
      euro_shape_svg: this.props.propsState.euroShape_svg || [],
    });
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let defValues = this.props.defaultValue;
        for (let prop in values) defValues[prop] = values[prop];
        this.props.form.setFieldsValue({ ...defValues });
      }
    });
    return e && e.fileList;
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.door_color_ids = values.door_color_ids.map((dc) => dc.value);
        this.props.handleSubmit(values);
        this.initiateState();
      }
    });
  };

  handleFilterCollection = (value) => {
    const { collectionData } = this.props.dataSource;
    const filter_collection = collectionData.filter((element) => {
      return element.manufacturer_id === value;
    });
    this.setState({ filter_collection, filter_door_color: [] });
    this.props.form.setFieldsValue({
      collection_id: null,
      door_color_ids: null,
    });
  };

  handleFilterDoorColor = (value, type) => {
    const { doorColorData } = this.props.dataSource;
    const { collection_id } = this.props.form.getFieldsValue(["collection_id"]);
    var filter_door_color = [];
    if (type === "collection") {
      filter_door_color = doorColorData.filter(
        (element) => element.collection_id === value
      );
    }
    var door_color_ids = undefined;
    if (filter_door_color.length !== 0) {
      door_color_ids = [];
      filter_door_color.forEach((fElement) => {
        door_color_ids.push({ value: fElement.id, label: fElement.name });
      });
    }
    this.setState({ filter_door_color });
    this.props.form.setFieldsValue({ door_color_ids: door_color_ids });
  };
  handleCDSChange = (value, index, type, cnt) => {
    const defValues = this.props.defaultValue;

    const cds_select = this.state.cds_select[type];
    const prevState = cds_select[index];
    if (prevState.length > value.length) {
      let i, j;
      for (i = 0; i < prevState.length; i++) {
        for (j = 0; j < value.length; j++) if (prevState[i] === value[j]) break;
        if (j === value.length) defValues[`${type}_${prevState[i] + 1}`] = [];
      }
    }
    cds_select[index] = value;

    const newState = this.state.cds_select;
    newState[type] = cds_select;
    this.setState({ cds_select: newState });

    const defKeys = [];
    for (let i = 0; i < cnt; i++) defKeys.push(`${type}_${i + 1}`);
    for (let i = 0; i < cnt; i++) {
      for (let j = 1; j < cds_select[i].length; j++)
        for (let k = 0; k < cnt; k++)
          if (k === cds_select[i][j]) {
            defValues[defKeys[k]] = defValues[defKeys[i]];
            defKeys[k] = `${type}_${i + 1}`;
          }
    }
    this.props.form.setFieldsValue({ ...defValues });
  };
  getKeysAndLabels = (label, type, cnt) => {
    const cds_select = this.state.cds_select[type];
    const labels = [],
      defKeys = [];
    for (let i = 0; i < cnt; i++) {
      labels.push(label + (i + 1));
      defKeys.push(`${type}_${i + 1}`);
    }

    for (let i = 0; i < cnt; i++) {
      for (let j = 1; j < cds_select[i].length; j++)
        for (let k = 0; k < cnt; k++)
          if (k === cds_select[i][j]) defKeys[k] = `${type}_${i + 1}`;
    }
    return { labels, defKeys };
  };

  render() {
    const { filter_collection, filter_door_color } = this.state;
    const { type, drawerClasses, classes, open, anchor, variant } = this.props;
    const {
      collection_id,
      manufacturer_id,
      cabinet_door_style_name,
      basic_cds,
      base_gltf,
      sku,
    } = this.props.defaultValue;
    let { door_color_ids } = this.props.defaultValue;
    const { cds_type, cds_id } = this.props.configData;
    const { getFieldDecorator, setFieldsValue } = this.props.form;
    const { manufacturerData, cabinetDoorStyle, ph_info, is_not_molding } =
      this.props.dataSource;

    const menuItems = [];
    cabinetDoorStyle.forEach((fElement) => {
      if (cds_type === "new" || fElement.id !== cds_id)
        menuItems.push(
          <Option key={fElement.id} value={fElement.id}>
            {fElement.cabinet_door_style_name}
          </Option>
        );
    });
    const manufacturerItems = [];
    manufacturerData.forEach((fElement) => {
      manufacturerItems.push(
        <Option key={fElement.id} value={fElement.id}>
          {fElement.name}
        </Option>
      );
    });
    const collectionItems = [];
    filter_collection.forEach((fElement) => {
      collectionItems.push(
        <Option key={fElement.id} value={fElement.id}>
          {fElement.collection_name}
        </Option>
      );
    });
    const doorColorValues = [];
    filter_door_color.forEach((fElement) => {
      doorColorValues.push({ value: fElement.id, label: fElement.name });
    });

    let props_door_color_ids = door_color_ids;
    door_color_ids = [];
    filter_door_color.forEach((fElement) => {
      if (props_door_color_ids.find((dcid) => fElement.id == dcid)) {
        door_color_ids.push({ value: fElement.id, label: fElement.name });
      }
    });

    let labelAndKey = this.getKeysAndLabels("Door", "door", ph_info.PH_DOOR);
    const doorLabels = labelAndKey.labels,
      doorDefKeys = labelAndKey.defKeys;

    const doorItems = [];
    for (let i = 0; i < ph_info.PH_DOOR; i++) {
      let key = "door_" + (i + 1);
      //let label = "Door " + (i + 1);
      doorItems.push(
        <Row gutter={8} key={key}>
          <Col span={2} />
          <Col span={22}>
            <Col span={9} style={{ zIndex: 3, top: -9 }}>
              <Select
                mode="multiple"
                //className={`cds_door_${i}`}
                dropdownStyle={{
                  zIndex: 10000,
                  maxHeight: 400,
                  overflow: "auto",
                }}
                onChange={(value) => {
                  this.handleCDSChange(value, i, "door", ph_info.PH_DOOR);
                }}
                defaultValue={i}
                disabled={key !== doorDefKeys[i] ? true : false}
              >
                {doorLabels.map((label, index) => (
                  <Option
                    key={`${key}option`}
                    value={index}
                    disabled={
                      index === i ||
                      (Array.isArray(this.props.defaultValue[doorDefKeys[i]])
                        ? this.props.defaultValue[doorDefKeys[i]].length === 0
                        : false)
                        ? true
                        : false
                    }
                    selected={i === index ? 1 : 0}
                  >
                    {" "}
                    {label}{" "}
                  </Option>
                ))}
              </Select>
            </Col>
            <FormItem
              // key={key}
              {...formItemLayout}
            >
              {getFieldDecorator(key, {
                initialValue: this.props.defaultValue[doorDefKeys[i]] || [],
                valuePropName: "fileList",
                getValueFromEvent: this.normFile,
              })(
                <Upload
                  multiple={true}
                  beforeUpload={(fileList) => {
                    return false;
                  }}
                  disabled={key !== doorDefKeys[i] ? true : false}
                >
                  <Button
                    variant="outlined"
                    color="default"
                    className={classes.button}
                    disabled={key !== doorDefKeys[i] ? true : false}
                  >
                    Upload
                    <CloudUploadIcon className={classes.rightIcon} />
                  </Button>
                </Upload>
              )}
            </FormItem>
          </Col>
        </Row>
      );
    }

    labelAndKey = this.getKeysAndLabels(
      "Fixed Drawer Door",
      "fixed_drawer_door",
      ph_info.PH_FIXED_DRAWER_DOOR
    );
    const fixedDrawDoorLabels = labelAndKey.labels,
      fixedDrawDoorDefKeys = labelAndKey.defKeys;

    const fixedDrawerDoorItems = [];
    for (let i = 0; i < ph_info.PH_FIXED_DRAWER_DOOR; i++) {
      let key = "fixed_drawer_door_" + (i + 1);
      //let label = "Fixed Drawer Door " + (i + 1);
      fixedDrawerDoorItems.push(
        <Row gutter={8} key={key}>
          <Col span={2} />
          <Col span={22}>
            <Col span={11} style={{ zIndex: 3, top: -9 }}>
              <Select
                mode="multiple"
                dropdownStyle={{
                  zIndex: 10000,
                  maxHeight: 400,
                  overflow: "auto",
                }}
                onChange={(value) => {
                  this.handleCDSChange(value, i, "drawer", ph_info.PH_DRAWER);
                }}
                defaultValue={i}
                disabled={key !== fixedDrawDoorDefKeys[i] ? true : false}
              >
                {fixedDrawDoorLabels.map((label, index) => (
                  <Option
                    key={`${key}option`}
                    value={index}
                    disabled={
                      index === i ||
                      (Array.isArray(
                        this.props.defaultValue[fixedDrawDoorDefKeys[i]]
                      )
                        ? this.props.defaultValue[fixedDrawDoorDefKeys[i]]
                            .length === 0
                        : false)
                        ? true
                        : false
                    }
                    selected={i === index ? 1 : 0}
                  >
                    {" "}
                    {label}{" "}
                  </Option>
                ))}
              </Select>
            </Col>
            <FormItem
              {...formItemLayout}
              //label={label}
            >
              {getFieldDecorator(key, {
                initialValue:
                  this.props.defaultValue[fixedDrawDoorDefKeys[i]] || [],
                valuePropName: "fileList",
                getValueFromEvent: this.normFile,
              })(
                <Upload
                  multiple={true}
                  beforeUpload={(fileList) => {
                    return false;
                  }}
                  disabled={key !== fixedDrawDoorDefKeys[i] ? true : false}
                >
                  <Button
                    variant="outlined"
                    color="default"
                    className={classes.button}
                    disabled={key !== fixedDrawDoorDefKeys[i] ? true : false}
                  >
                    Upload
                    <CloudUploadIcon className={classes.rightIcon} />
                  </Button>
                </Upload>
              )}
            </FormItem>
          </Col>
        </Row>
      );
    }

    labelAndKey = this.getKeysAndLabels("Drawer", "drawer", ph_info.PH_DRAWER);
    const drawerLabels = labelAndKey.labels,
      drawerDefKeys = labelAndKey.defKeys;

    labelAndKey = this.getKeysAndLabels(
      "Drawer Door",
      "drawer_door",
      ph_info.PH_DRAWER
    );
    const drawDoorLabels = labelAndKey.labels,
      drawDoorDefKeys = labelAndKey.defKeys;

    const drawerItems = [];
    for (let i = 0; i < ph_info.PH_DRAWER; i++) {
      let key = "drawer_" + (i + 1);
      //let label = "Drawer " + (i + 1);
      let key2 = "drawer_door_" + (i + 1);
      //let label2 = "Drawer Door " + (i + 1);
      drawerItems.push(
        <Row gutter={8} key={key}>
          <Col span={2} />
          <Col span={9}>
            <Col span={11} style={{ zIndex: 3, top: -9 }}>
              <Select
                mode="multiple"
                dropdownStyle={{
                  zIndex: 10000,
                  maxHeight: 400,
                  overflow: "auto",
                }}
                onChange={(value) => {
                  this.handleCDSChange(value, i, "drawer", ph_info.PH_DRAWER);
                }}
                defaultValue={i}
                disabled={key !== drawerDefKeys[i] ? true : false}
              >
                {drawerLabels.map((label, index) => (
                  <Option
                    key={`${key}option`}
                    value={index}
                    disabled={
                      index === i ||
                      (Array.isArray(this.props.defaultValue[drawerDefKeys[i]])
                        ? this.props.defaultValue[drawerDefKeys[i]].length === 0
                        : false)
                        ? true
                        : false
                    }
                    selected={i === index ? 1 : 0}
                  >
                    {" "}
                    {label}{" "}
                  </Option>
                ))}
              </Select>
            </Col>
            <FormItem {...formItemLayout}>
              {getFieldDecorator(key, {
                initialValue: this.props.defaultValue[drawerDefKeys[i]] || [],
                valuePropName: "fileList",
                getValueFromEvent: this.normFile,
              })(
                <Upload
                  multiple={true}
                  beforeUpload={(fileList) => {
                    return false;
                  }}
                  disabled={key !== drawerDefKeys[i] ? true : false}
                >
                  <Button
                    variant="outlined"
                    color="default"
                    className={classes.button}
                    disabled={key !== drawerDefKeys[i] ? true : false}
                  >
                    Upload
                    <CloudUploadIcon className={classes.rightIcon} />
                  </Button>
                </Upload>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <Col span={11} style={{ zIndex: 3, top: -9 }}>
              <Select
                mode="multiple"
                dropdownStyle={{
                  zIndex: 10000,
                  maxHeight: 400,
                  overflow: "auto",
                }}
                onChange={(value) => {
                  this.handleCDSChange(
                    value,
                    i,
                    "drawer_door",
                    ph_info.PH_DRAWER
                  );
                }}
                defaultValue={i}
                disabled={key2 !== drawDoorDefKeys[i] ? true : false}
              >
                {drawDoorLabels.map((label, index) => (
                  <Option
                    key={`${key2}option`}
                    value={index}
                    disabled={
                      index === i ||
                      (Array.isArray(
                        this.props.defaultValue[drawDoorDefKeys[i]]
                      )
                        ? this.props.defaultValue[drawDoorDefKeys[i]].length ===
                          0
                        : false)
                        ? true
                        : false
                    }
                    selected={i === index ? 1 : 0}
                  >
                    {" "}
                    {label}{" "}
                  </Option>
                ))}
              </Select>
            </Col>
            <FormItem key={key2} {...formItemLayout}>
              {getFieldDecorator(key2, {
                initialValue: this.props.defaultValue[drawDoorDefKeys[i]] || [],
                valuePropName: "fileList",
                getValueFromEvent: this.normFile,
              })(
                <Upload
                  multiple={true}
                  beforeUpload={(fileList) => {
                    return false;
                  }}
                  disabled={key2 !== drawDoorDefKeys[i] ? true : false}
                >
                  <Button
                    variant="outlined"
                    color="default"
                    className={classes.button}
                    disabled={key2 !== drawDoorDefKeys[i] ? true : false}
                  >
                    Upload
                    <CloudUploadIcon className={classes.rightIcon} />
                  </Button>
                </Upload>
              )}
            </FormItem>
          </Col>
        </Row>
      );
    }

    const euroItems = [];
    euroItems.push(
      <React.Fragment key="euroItems">
        <Row gutter={8}>
          <Col span={2} />
          <Col span={22}>
            <FormItem
              key="is_euro_cabinet"
              {...formItemLayout}
              label="Euro Cabinet"
            >
              {getFieldDecorator("is_euro_cabinet", {
                valuePropName: "checked",
                initialValue: this.props.isEuroCabinet ? true : false,
              })(
                <Checkbox
                  onChange={(event) => {
                    this.props.setIsEuroCabinet(event.target.checked);
                  }}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={2} />
          <Col span={22}>
            <FormItem
              key="euro_shape_svg"
              {...formItemLayout}
              label="Shape(SVG)"
            >
              {getFieldDecorator("euro_shape_svg", {
                initialValue: this.props.propsState.euroShape_svg,
                valuePropName: "fileList",
                getValueFromEvent: this.props.normFile,
              })(
                <Upload
                  listType="picture-card"
                  className="upload-list-inline"
                  onChange={
                    !this.props.propsState.is_not_molding &&
                    ((info) => {
                      const fData = info.fileList.filter((e) =>
                        e.name.endsWith(".svg")
                      );
                      if (fData.length === 1) {
                        this.props.setFieldsValue(fData[0].name.slice(0, -4));
                      } else {
                        this.props.setFieldsValue("");
                      }
                    })
                  }
                  onRemove={(file) => {
                    this.setState({ euro_shape_svg: [] });
                  }}
                  beforeUpload={(file, fileList) => {
                    this.setState({ euro_shape_svg: fileList });
                    return false;
                  }}
                  disabled={!this.props.isEuroCabinet}
                >
                  {/* {this.props.propsState.shape_svg.length == 0 && this.props.uploadButton} */}
                  {this.state.euro_shape_svg &&
                    this.state.euro_shape_svg.length === 0 &&
                    this.props.uploadButton}
                </Upload>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={2} />
          <Col span={22}>
            <FormItem key="euro_width" {...formItemLayout} label="Width">
              {getFieldDecorator("width", {
                initialValue: {
                  number: this.props.euro_width
                    ? this.props.euro_width
                    : this.props.width.number,
                  currency: this.props.euro_width_unit || "inch",
                },
                // rules: [{ validator: this.props.checkNumber }],
              })(<UnitInput disabled={!this.props.isEuroCabinet} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={2} />
          <Col span={22}>
            <FormItem key="euro_length" {...formItemLayout} label="Depth">
              {getFieldDecorator("length", {
                initialValue: {
                  number: this.props.euro_length
                    ? this.props.euro_length
                    : this.props.length.number,
                  currency: this.props.euro_length_unit || "inch",
                },
                // rules: [{ validator: this.props.checkNumber }],
              })(<UnitInput disabled={!this.props.isEuroCabinet} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={2} />
          <Col span={22}>
            <FormItem key="euro_height" {...formItemLayout} label="Height">
              {getFieldDecorator("height", {
                initialValue: {
                  number: this.props.euro_height
                    ? this.props.euro_height
                    : this.props.height.number,
                  currency: this.props.euro_height_unit || "inch",
                },
                // rules: [
                //   {
                //     validator: this.props.propsState.is_not_molding
                //       ? this.props.checkNumber
                //       : this.props.checkHeight,
                //   },
                // ],
              })(<UnitInput disabled={!this.props.isEuroCabinet} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={2} />
          <Col span={22}>
            <FormItem
              key="euro_leftBlindLength"
              {...formItemLayout}
              label="Left Blind Length"
            >
              {getFieldDecorator("leftBlindLength", {
                initialValue: {
                  number:
                    this.props.leftBlindLength.number !== undefined
                      ? this.props.leftBlindLength.number
                      : 0,
                  currency: this.props.propsState.leftBlindLength_unit,
                },
              })(<UnitInput disabled={!this.props.isEuroCabinet} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={2} />
          <Col span={22}>
            <FormItem
              key="euro_rightBlindLength"
              {...formItemLayout}
              label="Right Blind Length"
            >
              {getFieldDecorator("rightBlindLength", {
                initialValue: {
                  number:
                    this.props.rightBlindLength.number !== undefined
                      ? this.props.rightBlindLength.number
                      : 0,
                  currency: this.props.propsState.rightBlindLength_unit,
                },
              })(<UnitInput disabled={!this.props.isEuroCabinet} />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={2} />
          <Col span={22}>
            <FormItem key="euro_comments" {...formItemLayout} label="Comments">
              {getFieldDecorator("comments", {
                initialValue: this.props.propsState.comments,
              })(
                <TextArea
                  placeholder="Enter comments"
                  disabled={!this.props.isEuroCabinet}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </React.Fragment>
    );

    return (
      <Drawer
        style={{ zIndex: 700 }}
        anchor={anchor}
        open={open}
        variant={variant}
        onClose={() => {
          this.props.onClose();
        }}
        classes={drawerClasses}
        onRendered={() => {
          this.setState({
            filter_collection: this.props.dataSource.cds_filter_collection,
            filter_door_color: this.props.dataSource.cds_filter_door_color,
            euro_shape_svg: this.props.propsState.euroShape_svg,
          });
        }}
      >
        <div tabIndex={0} role="button">
          <Title level={3} className="pt-16" style={{ textAlign: "center" }}>
            {type === "new" ? "Add" : "Edit"} Cabinet Door Style
          </Title>
          <Divider />
          <Form layout="vertical" style={{ marginTop: "20px" }}>
            <Row gutter={8}>
              <Col span={2} />
              <Col span={22}>
                <FormItem
                  key="cabinet_door_style_name"
                  {...formItemLayout}
                  label="Cabinet Door Style Name"
                >
                  {getFieldDecorator("cabinet_door_style_name", {
                    initialValue: cabinet_door_style_name,
                    rules: [
                      {
                        required: true,
                        message: "Enter Cabinet Door Style Name.",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Enter Cabinet Door Style Name"
                      // onChange={(e)=> {
                      //     this.setState({cabinet_door_style_name: e.target.value});
                      // }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={2} />
              <Col span={22}>
                <FormItem
                  key="basic_cds_id"
                  {...formItemLayout}
                  label="Basic CDS"
                >
                  {getFieldDecorator("basic_cds", {
                    initialValue: basic_cds,
                  })(
                    <Select
                      mode="single"
                      dropdownStyle={{
                        zIndex: 10000,
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      style={{ width: "100%" }}
                      placeholder="Please select basic cds."
                    >
                      {menuItems}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            {is_not_molding && (
              <Row gutter={8}>
                <Col span={2} />
                <Col span={22}>
                  <FormItem
                    key="base_gltf"
                    {...formItemLayout}
                    label="Main GLTF"
                  >
                    {getFieldDecorator("base_gltf", {
                      initialValue: base_gltf,
                      valuePropName: "fileList",
                      getValueFromEvent: this.normFile,
                    })(
                      <Upload
                        multiple={true}
                        beforeUpload={(fileList) => {
                          return false;
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="default"
                          className={classes.button}
                        >
                          Upload
                          <CloudUploadIcon className={classes.rightIcon} />
                        </Button>
                      </Upload>
                    )}
                  </FormItem>
                </Col>
              </Row>
            )}
            <Row gutter={8}>
              <Col span={2} />
              <Col span={22}>
                <FormItem key="sku" {...formItemLayout} label="SKU">
                  {getFieldDecorator("sku", {
                    initialValue: sku,
                  })(<Input placeholder="Enter Cabinet Door Style SKU" />)}
                </FormItem>
              </Col>
            </Row>
            {doorItems}
            {fixedDrawerDoorItems}
            {drawerItems}
            <Row gutter={8}>
              <Col span={2} />
              <Col span={22}>
                <FormItem
                  key="manufacturer_id"
                  {...formItemLayout}
                  label="Manufacturer Name"
                >
                  {getFieldDecorator("manufacturer_id", {
                    initialValue: manufacturer_id,
                    rules: [
                      {
                        required: true,
                        message: "Please select your manufacturer!",
                      },
                    ],
                  })(
                    <Select
                      dropdownStyle={{
                        zIndex: 10000,
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      placeholder="Please select your manufacturer"
                      onChange={(value) => {
                        this.handleFilterCollection(value);
                      }}
                    >
                      {manufacturerItems}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={2} />
              <Col span={22}>
                <FormItem
                  key="collection_id"
                  {...formItemLayout}
                  label="Collection Name"
                >
                  {getFieldDecorator("collection_id", {
                    initialValue: collection_id,
                    rules: [
                      {
                        required: true,
                        message: "Please select your collection!",
                      },
                    ],
                  })(
                    <Select
                      dropdownStyle={{
                        zIndex: 10000,
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      placeholder="Please select your collection"
                      onChange={(value) => {
                        this.handleFilterDoorColor(value, "collection");
                      }}
                    >
                      {collectionItems}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={2} />
              <Col span={22}>
                <FormItem
                  key="door_color_ids"
                  {...formItemLayout}
                  label="Door Colors"
                >
                  {getFieldDecorator("door_color_ids", {
                    initialValue: door_color_ids,
                    rules: [
                      {
                        required: true,
                        message: "Please Cabinet Door Color.",
                      },
                    ],
                  })(<ReactSelect options={doorColorValues} isMulti={true} />)}
                </FormItem>
              </Col>
            </Row>
            {euroItems}
            <div
              style={{
                left: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
              }}
            >
              <Button
                style={{ marginRight: 8 }}
                onClick={() => {
                  this.props.handleCancel();
                  this.initiateState();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
              >
                {type === "new" ? "ADD" : "UPDATE"}
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
    );
  }
}

const CabinetDoorStyleDrawer = Form.create()(CabinetDoorStyleDrawerComponent);
export default withStyles(styles, { withTheme: true })(CabinetDoorStyleDrawer);

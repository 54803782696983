import * as Actions from "../actions";

const initialState = {
  update: [],
};

const updateReducer = function (state = initialState, action) {
  switch (action.type) {
    default: {
      return state;
    }
  }
};

export default updateReducer;

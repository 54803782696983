import axios from "axios";
import { showMessage } from "app/store/actions/fuse";
import {
  API_SERVER_URL,
  jsonArray,
  uploadFiles,
  putFiles,
  deleteFiles,
} from "app/main/config";
import { uploadLoading } from "app/store/actions/fuse";

export const GET_DOOR_COLOR_FAMILYES =
  "[DOOR_COLOR_FAMILIES] GET DOOR COLOR FAMILIES";
export function getDoorColorFamilies() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/doorcolorfamily/read/doorcolorfamily`)
      .then((response) => {
        const { doorcolorfamilyData } = response.data;
        dispatch({
          type: GET_DOOR_COLOR_FAMILYES,
          doorColorFamilies: doorcolorfamilyData,
        });
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to load FLOOR STYLES data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

export const ADD_DOOR_COLOR_FAMILY =
  "[DOOR_COLOR_FAMILIES] ADD DOOR COLOR FAMILY";
export function addDoorColorFamily(data) {
  var newData = {
    name: data.name,
    thumbnail: data.thumbnail[0].name,
  };

  const formData = new FormData();
  formData.append("thumbnail", data.thumbnail[0].originFileObj);

  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/doorcolorfamily/insert/doorcolorfamily`, {
        ...newData,
      })
      .then((res) => {
        dispatch(uploadLoading(true));
        if (process.env.REACT_APP_MODE === "production") {
          uploadFiles(
            data.thumbnail.filter((file) => file.originFileObj !== undefined),
            `uploads/DoorColorFamily/${
              res.data.guid + "_" + res.data.id
            }/thumbnail/`
          )
            .then((thumbnail) => {
              const uploadData = [
                {
                  fieldName: "thumbnail",
                  type: "single",
                  data: thumbnail,
                },
              ];
              axios
                .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                  id: res.data.id,
                  tableName: "door_color_family",
                  data: uploadData,
                })
                .then((response) => {
                  const { success } = response.data;
                  dispatch(uploadLoading(false));
                  if (success === true) {
                    dispatch(
                      showMessage({
                        message: "Successfully Inserted",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        variant: "success",
                      })
                    );
                    Promise.all([
                      dispatch({
                        type: ADD_DOOR_COLOR_FAMILY,
                      }),
                    ]).then(() => dispatch(getDoorColorFamilies()));
                  } else {
                    console.log(success);
                  }
                });
            })
            .catch((err) => {
              console.log("failed to upload floor style thumbnail. ", err);
              return;
            });
        } else {
          formData.append("guid", res.data.guid);
          formData.append("id", res.data.id);
          axios
            .post(`${API_SERVER_URL}/api/doorcolorfamily/upload`, formData)
            .then((response) => {
              const { success } = response.data;
              dispatch(uploadLoading(false));
              if (success === true) {
                axios.post(`${API_SERVER_URL}/uploads/clear`).then((res) => {
                  if (res.data.success === true) {
                    dispatch(
                      showMessage({
                        message: "Successfully Inserted",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        variant: "success",
                      })
                    );
                    Promise.all([
                      dispatch({
                        type: ADD_DOOR_COLOR_FAMILY,
                      }),
                    ]).then(() => dispatch(getDoorColorFamilies()));
                  }
                });
              }
            })
            .catch((err) => {
              dispatch(
                showMessage({
                  message: "Failed to add FLOOR STYLES data",
                  autoHideDuration: 2000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  variant: "error",
                })
              );

              console.log("err", err);
            });
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to upload image",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );

        console.log("err", err);
      });
  };
}

export const DELETE_DOOR_COLOR_FAMILY =
  "[DOOR_COLOR_FAMILIES] DELETE DOOR COLOR FAMILY";
export function deleteDoorColorFamily(record) {
  return (dispatch) => {
    var p1 = new Promise((resolve, reject) => {
      dispatch(uploadLoading(true));
      if (process.env.REACT_APP_MODE === "production") {
        const data = [];
        data.push(record.thumbnail);
        deleteFiles(data)
          .then((res) => {
            resolve("success");
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        resolve("success");
      }
    });
    p1.then((p1Value) => {
      axios
        .post(`${API_SERVER_URL}/api/doorcolorfamily/remove/doorcolorfamily`, {
          id: record.id,
        })
        .then((response) => {
          const { success } = response.data;
          dispatch(uploadLoading(false));
          if (success === true) {
            dispatch(
              showMessage({
                message: "Successfully Deleted",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "success",
              })
            );

            Promise.all([
              dispatch({
                type: DELETE_DOOR_COLOR_FAMILY,
              }),
            ]).then(() => dispatch(getDoorColorFamilies()));
          }
        })
        .catch((err) => {
          dispatch(uploadLoading(false));
          dispatch(
            showMessage({
              message: "Failed to delete FLOOR STYLES data",
              autoHideDuration: 4000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
            })
          );

          console.log("err", err);
        });
    }).catch((err) => {
      dispatch(uploadLoading(false));
      dispatch(
        showMessage({
          message: "AWS S3 DELETE COUNT_TOP ERROR",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
      return;
    });
  };
}

export const UPDATE_DOOR_COLOR_FAMILY =
  "[DOOR_COLOR_FAMILIES] UPDATE FLOOR STYLES";
export function updateDoorColorFamily(data) {
  console.log(data);

  var updateData = {
    id: data.id,
    name: data.name,
    thumbnail: data.thumbnail[0].name,
  };

  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/doorcolorfamily/update/doorcolorfamily`, {
        ...updateData,
      })
      .then((res) => {
        dispatch(uploadLoading(true));
        if (process.env.REACT_APP_MODE === "production") {
          putFiles(
            data.thumbnail.filter((file) => file.originFileObj !== undefined),
            `uploads/DoorColorFamily/${data.guid + "_" + data.id}/thumbnail/`
          )
            .then((thumbnail) => {
              const uploadData = [
                {
                  fieldName: "thumbnail",
                  type: "single",
                  data: thumbnail,
                },
              ];

              axios
                .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                  id: data.id,
                  tableName: "door_color_family",
                  data: uploadData,
                })
                .then((response) => {
                  const { success } = response.data;
                  dispatch(uploadLoading(false));
                  if (success === true) {
                    dispatch(
                      showMessage({
                        message: "Successfully Updated",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        variant: "success",
                      })
                    );
                    Promise.all([
                      dispatch({
                        type: UPDATE_DOOR_COLOR_FAMILY,
                      }),
                    ]).then(() => dispatch(getDoorColorFamilies()));
                  } else {
                    console.log(success);
                  }
                });
            })
            .catch((err) => {
              console.log("failed to put door color family thumbnail. ", err);
              return;
            });
        } else {
          if (data.thumbnail[0].originFileObj !== undefined) {
            const formData = new FormData();
            formData.append("guid", data.guid);
            formData.append("id", data.id);
            if (data.thumbnail[0].originFileObj !== undefined) {
              formData.append("thumbnail", data.thumbnail[0].originFileObj);
            }
            axios
              .post(`${API_SERVER_URL}/api/doorcolorfamily/upload`, formData)
              .then((response) => {
                const { success } = response.data;
                dispatch(uploadLoading(false));
                if (success === true) {
                  axios.post(`${API_SERVER_URL}/uploads/clear`).then((res) => {
                    if (res.data.success === true) {
                      dispatch(
                        showMessage({
                          message: "Successfully Updated",
                          autoHideDuration: 2000,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          variant: "success",
                        })
                      );
                      Promise.all([
                        dispatch({
                          type: UPDATE_DOOR_COLOR_FAMILY,
                        }),
                      ]).then(() => dispatch(getDoorColorFamilies()));
                    }
                  });
                }
              })
              .catch((err) => {
                dispatch(
                  showMessage({
                    message: "Failed to update FLOOR STYLES data",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                    variant: "error",
                  })
                );

                console.log("err", err);
              });
          } else {
            dispatch(uploadLoading(false));
            dispatch(
              showMessage({
                message: "Successfully Updated",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "success",
              })
            );
            Promise.all([
              dispatch({
                type: UPDATE_DOOR_COLOR_FAMILY,
              }),
            ]).then(() => dispatch(getDoorColorFamilies()));
          }
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to upload image",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );

        console.log("err", err);
      });
  };
}

export const SET_DOOR_COLOR_FAMILY_SORT_ORDER =
  "[DOOR_COLOR_FAMILY] SET SORT ORDER";
export function setSortOrder(data) {
  return (dispatch) => {
    dispatch(uploadLoading(true));
    axios
      .post(`${API_SERVER_URL}/api/base/setSortOrder`, {
        id: data.id,
        sort_order: data.sort_order,
        table_name: "door_color_family",
      })
      .then((response) => {
        const { success } = response.data;
        if (success === true) {
          dispatch(uploadLoading(false));
          Promise.all([
            dispatch({
              type: SET_DOOR_COLOR_FAMILY_SORT_ORDER,
            }),
          ]).then(() => dispatch(getDoorColorFamilies()));
        } else {
          dispatch(uploadLoading(false));
        }
      })
      .catch((err) => {
        dispatch(uploadLoading(false));
        dispatch(
          showMessage({
            message: "Failed to set sort order",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("failed to set sort order in door category.", err);
      });
  };
}

import axios from "axios";
import { showMessage } from "app/store/actions/fuse";
import {
  API_SERVER_URL,
  jsonArray,
  uploadFiles,
  putFiles,
  deleteFiles,
  canUpload,
} from "app/main/config";
import { uploadLoading } from "app/store/actions/fuse";

export const GET_DOOR_HANDLE = "[DOOR_HANDLE] GET DOOR HANDLE";
export function getDoorHandle() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/doorHandle/read/door_handle`)
      .then((response) => {
        const { doorHandle } = response.data;
        dispatch({
          type: GET_DOOR_HANDLE,
          doorHandle: doorHandle,
        });
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to load DOOR HANDLE data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

export const ADD_DOOR_HANDLE = "[DOOR_HANDLE] ADD DOOR HANDLE";
export function addDoorHandle(data) {
  var data_gltf = {
    gltf: jsonArray(data.gltf),
  };

  var data_string = JSON.stringify(data_gltf);

  var newData = {
    name: data.name,
    thumbnail: data.thumbnail[0].name,
    gltf: data_string,
  };

  const formData = new FormData();
  formData.append("thumbnail", data.thumbnail[0].originFileObj);
  data.gltf.forEach((fElement) => {
    formData.append("gltf", fElement.originFileObj);
  });

  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/doorHandle/insert/door_handle`, {
        ...newData,
      })
      .then((res) => {
        dispatch(uploadLoading(true));
        if (process.env.REACT_APP_MODE === "production") {
          uploadFiles(
            data.gltf.filter((file) => file.originFileObj !== undefined),
            `uploads/DoorHandle/${
              res.data.data.guid + "_" + res.data.data.id
            }/gltf/`
          )
            .then((gltf) => {
              uploadFiles(
                data.thumbnail.filter(
                  (file) => file.originFileObj !== undefined
                ),
                `uploads/DoorHandle/${
                  res.data.data.guid + "_" + res.data.data.id
                }/thumbnail/`
              )
                .then((thumbnail) => {
                  const uploadData = [
                    {
                      fieldName: "gltf",
                      type: "multi",
                      data: [
                        {
                          jsonFieldName: "gltf",
                          data: gltf,
                        },
                      ],
                    },
                    {
                      fieldName: "thumbnail",
                      type: "single",
                      data: thumbnail,
                    },
                  ];
                  axios
                    .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                      id: res.data.data.id,
                      tableName: "door_handle",
                      data: uploadData,
                    })
                    .then((response) => {
                      const { success } = response.data;
                      dispatch(uploadLoading(false));
                      if (success === true) {
                        dispatch(
                          showMessage({
                            message: "Successfully Inserted",
                            autoHideDuration: 2000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                            variant: "success",
                          })
                        );
                        Promise.all([
                          dispatch({
                            type: ADD_DOOR_HANDLE,
                          }),
                        ]).then(() => dispatch(getDoorHandle()));
                      } else {
                        console.log(success);
                      }
                    });
                })
                .catch((err) => {
                  console.log("failed to upload door handle thumbnail. ", err);
                  return;
                });
            })
            .catch((err) => {
              console.log("failed to upload door handle gltfs. ", err);
              return;
            });
        } else {
          formData.append("guid", res.data.data.guid);
          formData.append("id", res.data.data.id);
          axios
            .post(`${API_SERVER_URL}/api/doorHandle/upload`, formData)
            .then((response) => {
              const { success } = response.data;
              dispatch(uploadLoading(false));
              if (success === true) {
                axios.post(`${API_SERVER_URL}/uploads/clear`).then((res) => {
                  if (res.data.success === true) {
                    dispatch(
                      showMessage({
                        message: "Successfully Inserted",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        variant: "success",
                      })
                    );
                    Promise.all([
                      dispatch({
                        type: ADD_DOOR_HANDLE,
                      }),
                    ]).then(() => dispatch(getDoorHandle()));
                  }
                });
              }
            })
            .catch((err) => {
              dispatch(
                showMessage({
                  message: "Failed to add DOOR HANDLE data",
                  autoHideDuration: 2000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  variant: "error",
                })
              );

              console.log("err", err);
            });
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to upload image",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );

        console.log("err", err);
      });
  };
}

export const DELETE_DOOR_HANDLE = "[DOOR_HANDLE] DELETE DOOR HANDLE";
export function deleteDoorHandle(record) {
  return (dispatch) => {
    var p1 = new Promise((resolve, reject) => {
      dispatch(uploadLoading(true));
      if (process.env.REACT_APP_MODE === "production") {
        const { gltf } = JSON.parse(record.gltf);
        gltf.push(record.thumbnail);
        deleteFiles(gltf)
          .then((res) => {
            resolve("success");
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        resolve("success");
      }
    });
    p1.then((p1Value) => {
      axios
        .post(`${API_SERVER_URL}/api/doorHandle/remove/door_handle`, {
          id: record.id,
        })
        .then((response) => {
          const { success } = response.data;
          dispatch(uploadLoading(false));
          if (success === true) {
            dispatch(
              showMessage({
                message: "Successfully Deleted",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "success",
              })
            );
            Promise.all([
              dispatch({
                type: DELETE_DOOR_HANDLE,
              }),
            ]).then(() => dispatch(getDoorHandle()));
          }
        })
        .catch((err) => {
          dispatch(uploadLoading(false));
          dispatch(
            showMessage({
              message: "Failed to delete DOOR HANDLE data",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
            })
          );

          console.log("err", err);
        });
    }).catch((err) => {
      dispatch(uploadLoading(false));
      dispatch(
        showMessage({
          message: "AWS S3 DELETE DOOR_HANDLE ERROR",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
      return;
    });
  };
}

export const UPDATE_DOOR_HANDLE = "[DOOR_HANDLE] UPDATE DOOR HANDLE";
export function updateDoorHandle(data, id, guid) {
  var data_gltf = {
    gltf: jsonArray(data.gltf),
  };
  var data_string = JSON.stringify(data_gltf);
  var updateData = {
    id: id,
    name: data.name,
    thumbnail: jsonArray([data.thumbnail[0]]),
    gltf: data_string,
  };

  // const formData = new FormData();
  // formData.append("thumbnail",data.thumbnail[0].originFileObj);
  // data.gltf.forEach(fElement => {
  //     formData.append("gltf",fElement.originFileObj);
  // })

  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/doorHandle/update/door_handle`, {
        ...updateData,
      })
      .then((res) => {
        dispatch(uploadLoading(true));
        if (process.env.REACT_APP_MODE === "production") {
          putFiles(
            data.gltf.filter((file) => file.originFileObj !== undefined),
            `uploads/DoorHandle/${guid + "_" + id}/gltf/`
          )
            .then((gltf) => {
              putFiles(
                data.thumbnail.filter(
                  (file) => file.originFileObj !== undefined
                ),
                `uploads/DoorHandle/${guid + "_" + id}/thumbnail/`
              )
                .then((thumbnail) => {
                  const uploadData = [
                    {
                      fieldName: "gltf",
                      type: "multi",
                      data: [
                        {
                          jsonFieldName: "gltf",
                          data: gltf,
                        },
                      ],
                    },
                    {
                      fieldName: "thumbnail",
                      type: "single",
                      data: thumbnail,
                    },
                  ];
                  axios
                    .post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                      id: id,
                      tableName: "door_handle",
                      data: uploadData,
                    })
                    .then((response) => {
                      const { success } = response.data;
                      dispatch(uploadLoading(false));
                      if (success === true) {
                        dispatch(
                          showMessage({
                            message: "Successfully Updated",
                            autoHideDuration: 2000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                            variant: "success",
                          })
                        );
                        Promise.all([
                          dispatch({
                            type: UPDATE_DOOR_HANDLE,
                          }),
                        ]).then(() => dispatch(getDoorHandle()));
                      } else {
                        console.log(success);
                      }
                    });
                })
                .catch((err) => {
                  console.log("failed to put door handle thumbnail. ", err);
                  return;
                });
            })
            .catch((err) => {
              console.log("failed to put door handle gltfs. ", err);
              return;
            });
        } else {
          if (
            data.thumbnail[0].originFileObj !== undefined ||
            canUpload(data.gltf)
          ) {
            const formData = new FormData();
            formData.append("guid", guid);
            formData.append("id", id);
            if (data.thumbnail[0].originFileObj !== undefined) {
              formData.append("thumbnail", data.thumbnail[0].originFileObj);
            }
            if (canUpload(data.gltf)) {
              data.gltf.forEach((fElement) => {
                formData.append("gltf", fElement.originFileObj);
              });
            }
            axios
              .post(`${API_SERVER_URL}/api/doorHandle/upload`, formData)
              .then((response) => {
                const { success } = response.data;
                dispatch(uploadLoading(false));
                if (success === true) {
                  axios.post(`${API_SERVER_URL}/uploads/clear`).then((res) => {
                    if (res.data.success === true) {
                      dispatch(
                        showMessage({
                          message: "Successfully Updated",
                          autoHideDuration: 2000,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          variant: "success",
                        })
                      );
                      Promise.all([
                        dispatch({
                          type: UPDATE_DOOR_HANDLE,
                        }),
                      ]).then(() => dispatch(getDoorHandle()));
                    }
                  });
                }
              })
              .catch((err) => {
                dispatch(
                  showMessage({
                    message: "Failed to update DOOR HANDLE data",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                    variant: "error",
                  })
                );

                console.log("err", err);
              });
          } else {
            dispatch(uploadLoading(false));
            dispatch(
              showMessage({
                message: "Successfully Updated",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                variant: "success",
              })
            );
            Promise.all([
              dispatch({
                type: UPDATE_DOOR_HANDLE,
              }),
            ]).then(() => dispatch(getDoorHandle()));
          }
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to upload image",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );

        console.log("err", err);
      });
  };
}

export const SET_DOOR_HANDLE_SORT_ORDER = "[DOOR_HANDLE] SET SORT ORDER";
export function setSortOrder(data) {
  return (dispatch) => {
    dispatch(uploadLoading(true));
    axios
      .post(`${API_SERVER_URL}/api/base/setSortOrder`, {
        id: data.id,
        sort_order: data.sort_order,
        table_name: "door_handle",
      })
      .then((response) => {
        const { success } = response.data;
        if (success === true) {
          dispatch(uploadLoading(false));
          Promise.all([
            dispatch({
              type: SET_DOOR_HANDLE_SORT_ORDER,
            }),
          ]).then(() => dispatch(getDoorHandle()));
        } else {
          dispatch(uploadLoading(false));
        }
      })
      .catch((err) => {
        dispatch(uploadLoading(false));
        dispatch(
          showMessage({
            message: "Failed to set sort order",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("failed to set sort order in door style.", err);
      });
  };
}

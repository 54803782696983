import * as Actions from "../actions";

const initialState = {
  cabinetDoorStyle: [],
  collectionData: [],
  doorStyle: [],
  doorColor: [],
  manufacturerData: [],
  cds_ids: [],
};

const cabinetDoorStyleReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_CABINET_DOOR_STYLE_IDS:
    case Actions.ADD_CABINET_DOOR_STYLE:
    case Actions.DELETE_CABINET_DOOR_STYLE:
    case Actions.GET_CABINET_DOOR_STYLE:
    case Actions.GET_COLLECTION:
    case Actions.GET_DOOR_STYLE:
    case Actions.GET_MANUFACTURER:
    case Actions.GET_DOOR_COLOR: {
      return {
        ...state,
        ...action,
      };
    }

    default: {
      return state;
    }
  }
};

export default cabinetDoorStyleReducer;

import * as Actions from "../actions";

const initialState = {
  doorColor: [],
  doorColorFamilyData: [],
  doorStyle: [],
  collectionData: [],
};

const doorColorReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_DOOR_COLOR: {
      return {
        ...state,
        doorColor: action.doorColor,
      };
    }
    case Actions.GET_DOOR_COLOR_FAMILY: {
      return {
        ...state,
        ...action,
      };
    }
    case Actions.GET_DOOR_STYLE: {
      return {
        ...state,
        ...action,
      };
    }
    case Actions.GET_COLLECTION: {
      return {
        ...state,
        ...action,
      };
    }
    default: {
      return state;
    }
  }
};

export default doorColorReducer;

import FloorStyles from "./FloorStyles";
import { authRoles } from "app/auth";
export const FloorStylesConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/finishing_touch/floor_styles",
      component: FloorStyles,
    },
  ],
};

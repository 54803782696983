import React from "react";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as authActions from "app/auth/store/actions";
import * as appActions from "app/store/actions";
const FormItem = Form.Item;

class LoginFormComponent extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.submitLogin(values);
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.login.error && this.props.login.error.info) {
      this.props.showMessage({
        message: this.props.login.error.info,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        variant: "error",
      });

      this.props.login.error = null;
    }

    return null;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <FormItem>
          <label className="form-label mb-0">Username</label>
          {getFieldDecorator("username", {
            rules: [{ required: true, message: "Please input your username!" }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Username"
            />
          )}
        </FormItem>
        <FormItem>
          <label className="form-label mb-0">Password</label>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your Password!" }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator("remember", {
            valuePropName: "checked",
            initialValue: true,
          })(<Checkbox>Remember me</Checkbox>)}
        </FormItem>
        <div className="form-actions">
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Sign in
          </Button>
        </div>
      </Form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitLogin: authActions.submitLogin,
      showMessage: appActions.showMessage,
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  return {
    login: auth.login,
    user: auth.user,
  };
}

const LoginForm = Form.create()(LoginFormComponent);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
);

import axios from "axios";
import jwtDecode from "jwt-decode";
import FuseUtils from "@fuse/FuseUtils";
import { API_SERVER_URL } from "app/main/config";

class jwtService extends FuseUtils.EventEmitter {
  init() {
    // this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          console.log(err);
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if ( err)
            // if you ever get an unauthorized response, logout the user
            this.emit(
              "onAutoLogout",
              "Invalid access_token! There is a problem with Database."
            );
            this.setSession(null);
            this.setUser(null);
            return;
          }
          // throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    let access_token = this.getAccessToken();

    if (!access_token) {
      return;
    }
    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post("/api/auth/register", data).then((response) => {
        if (response.data.user) {
          this.setUser(response.data.user);
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (values) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_SERVER_URL}/api/user/login`, {
          values,
        })
        .then((response) => {
          if (response.data.user) {
            this.setUser(response.data.user);
            this.setSession(response.data.token);
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_SERVER_URL}/api/user/access-token`, {
          data: {
            access_token: this.getAccessToken(),
          },
        })
        .then((response) => {
          if (response.data.user) {
            // this.emit('onLoginSuccess', {message: 'You log in with token.', variant: 'success'});
            this.setUser(response.data.user);
            this.setSession(response.data.access_token);
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  updateUserData = (user) => {
    return axios.post("/api/auth/user/update", {
      user: user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    } else {
      localStorage.removeItem("jwt_access_token");
      // delete axios.defaults.headers.common['Authorization'];
    }
  };
  setUser = (user) => {
    if (user) {
      localStorage.setItem("jwt_access_user", JSON.stringify(user));
    } else {
      localStorage.removeItem("jwt_access_user");
    }
  };
  logout = () => {
    this.setSession(null);
    this.setUser(null);
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };
}

const instance = new jwtService();

export default instance;

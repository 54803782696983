import axios from "axios";
import { showMessage } from "app/store/actions/fuse";
import { API_SERVER_URL } from "app/main/config";
import { uploadLoading } from "app/store/actions/fuse";

export const GET_RTALIVE = "[RTALIVE] GET RTALIVE";

export function getRTAlive() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/magento/read/rtalive`)
      .then((response) => {
        const { success, data } = response.data;
        if (success) {
          dispatch({
            type: GET_RTALIVE,
            rtalive: data,
          });
        } else {
          dispatch(
            showMessage({
              message: "Failed to load RTALIVE data",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to load RTALIVE data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log(err);
      });
  };
}

import axios from "axios";
import { API_SERVER_URL } from "app/main/config";
import { showMessage } from "app/store/actions/fuse";

export const GET_CATALOG = "[CATALOG] GET CATALOG";
export const DELETE_CATALOG = "[CATALOG] DELETE CATALOG";
export const OPEN_NEW_USER_DIALOG = "[CATALOG] OPEN NEW USER DIALOG";
export const CLOSE_NEW_USER_DIALOG = "[CATALOG] CLOSE NEW USER DIALOG";
export const OPEN_EDIT_USER_DIALOG = "[CATALOG] OPEN EDIT USER DIALOG";
export const CLOSE_EDIT_USER_DIALOG = "[CATALOG] CLOSE EDIT USER DIALOG";
export const ADD_CATALOG = "[CATALOG] ADD CATALOG";
export const UPDATE_CATALOG = "[CATALOG] UPDATE CATALOG";

export function getCatalog() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/catalog/read/catalog`)
      .then((response) => {
        const { catalogData, subgroupData, colorAliasData, success } =
          response.data;
        if (success === true) {
          dispatch({
            type: GET_CATALOG,
            payload: { catalogData, subgroupData, colorAliasData },
          });
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to load CATALOG data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

export function deleteCatalog(id) {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/catalog/remove/catalog`, {
        id,
      })
      .then((response) => {
        const { catalogData, success } = response.data;
        if (success === true) {
          dispatch(
            showMessage({
              message: "Successfully Deleted",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
            })
          );
          dispatch(getCatalog());
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to delete CATALOG data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );

        console.log("err", err);
      });
  };
}

export function openNewUserDialog() {
  return {
    type: OPEN_NEW_USER_DIALOG,
  };
}

export function closeNewUserDialog() {
  return {
    type: CLOSE_NEW_USER_DIALOG,
  };
}

export function openEditUserDialog(data) {
  return {
    type: OPEN_EDIT_USER_DIALOG,
    data,
  };
}

export function closeEditUserDialog() {
  return {
    type: CLOSE_EDIT_USER_DIALOG,
  };
}

export function addUser(data) {
  return (dispatch) => {
    const request = axios.post(`${API_SERVER_URL}/api/catalog/insert/catalog`, {
      data,
    });

    return request
      .then((response) => {
        dispatch(
          showMessage({
            message: "Successfully Inserted",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
        Promise.all([
          dispatch({
            type: ADD_CATALOG,
          }),
        ]).then(() => dispatch(getCatalog()));
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to add CATALOG data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );

        console.log("err", err);
      });
  };
}

export function updateUser(data) {
  return (dispatch) => {
    const request = axios.post(`${API_SERVER_URL}/api/catalog/update/catalog`, {
      data,
    });

    return request
      .then((response) => {
        dispatch(
          showMessage({
            message: "Successfully Updated",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
        Promise.all([
          dispatch({
            type: UPDATE_CATALOG,
          }),
        ]).then(() => dispatch(getCatalog()));
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to update CATALOG data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );

        console.log("err", err);
      });
  };
}
export const GET_MANUFACTURER = "[CATALOG] GET MANUFACTURER";
export const ADD_MANUFACTURER = "[CATALOG] ADD MANUFACTURER";
export const DELETE_MANUFACTURER = "[CATALOG] DELETE MANUFACTURER";
export const SET_MANUFACTURER = "[CATALOG] SET_MANUFACTURER";
export const UPDATE_MANUFACTURER = "[CATALOG] UPDATE_MANUFACTURER";

export function setManufacturer(data) {
  return {
    type: SET_MANUFACTURER,
    payload: data,
  };
}
export function getManufacturer() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/catalog/read/manufacturer`)
      .then((response) => {
        const { manufacturer, color_familys, success } = response.data;
        if (success === true) {
          dispatch({
            type: GET_MANUFACTURER,
            payload: { manufacturer, color_familys },
          });
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to load MANUFACTURER data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

export function addManufacturer(data) {
  return (dispatch) => {
    const request = axios.post(
      `${API_SERVER_URL}/api/catalog/insert/manufacturer`,
      {
        data,
      }
    );

    return request
      .then((response) => {
        dispatch(
          showMessage({
            message: "Successfully Inserted",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
        Promise.all([
          dispatch({
            type: ADD_MANUFACTURER,
            payload: response.data.manufacturer_id,
          }),
        ]).then(() => dispatch(getCatalog()));
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to add MANUFACTURER data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );

        console.log("err", err);
      });
  };
}

export function deleteManufacturer(id) {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/catalog/remove/manufacturer`, id)
      .then((response) => {
        const { success } = response.data;
        if (success === true) {
          dispatch(
            showMessage({
              message: "Successfully Deleted",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
            })
          );
          dispatch(getCatalog());
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to delete MANUFACTURER data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );

        console.log("err", err);
      });
  };
}

export function updateManufacturer(data) {
  return (dispatch) => {
    const request = axios.post(
      `${API_SERVER_URL}/api/catalog/update/manufacturer`,
      { data }
    );

    return request
      .then((response) => {
        dispatch(
          showMessage({
            message: "Successfully Updated",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
        Promise.all([
          dispatch({
            type: UPDATE_MANUFACTURER,
          }),
        ]).then(() => dispatch(getCatalog()));
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to update Manufacturer data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );

        console.log("err", err);
      });
  };
}

import DoorColor from "./DoorColor";
import { authRoles } from "app/auth";
export const DoorColorConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/door/color",
      component: DoorColor,
    },
  ],
};

import axios from "axios";
import { API_SERVER_URL } from "app/main/config";
import { showMessage } from "app/store/actions/fuse";

export const GET_COLLECTION = "[COLLECTION] GET COLLECTION";
export const REMOVE_USER_DIALOG = "[COLLECTION] REMOVE USER DIALOG";
export const GET_MANUFACTURER = "[COLLECTION] GET MANUFACTURER";
export const DELETE_COLLECTION = "[COLLECTION] DELETE COLLECTION";
export const OPEN_NEW_USER_DIALOG = "[COLLECTION] OPEN NEW USER DIALOG";
export const CLOSE_NEW_USER_DIALOG = "[COLLECTION] CLOSE NEW USER DIALOG";
export const OPEN_EDIT_USER_DIALOG = "[COLLECTION] OPEN EDIT USER DIALOG";
export const CLOSE_EDIT_USER_DIALOG = "[COLLECTION] CLOSE EDIT USER DIALOG";
export const ADD_COLLECTION = "[COLLECTION] ADD COLLECTION";
export const UPDATE_COLLECTION = "[COLLECTION] UPDATE COLLECTION";

export function getCollection() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/collection/read/collection`)
      .then((response) => {
        const { collectionData, success } = response.data;
        if (success === true) {
          dispatch({
            type: GET_COLLECTION,
            payload: collectionData,
          });
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to load COLLECTION data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

export function getManufacturer() {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/manufacturer/read/manufacturer`)
      .then((response) => {
        const { manufacturerData, success } = response.data;
        if (success === true) {
          dispatch({
            type: GET_MANUFACTURER,
            payload: manufacturerData,
          });
        } else {
          dispatch({
            type: GET_MANUFACTURER,
            payload: manufacturerData,
          });
        }
      });
  };
}

export function deleteCollection(id) {
  return (dispatch) => {
    axios
      .post(`${API_SERVER_URL}/api/collection/delete/collection`, {
        id,
      })
      .then((response) => {
        const { collectionData, success } = response.data;
        if (success === true) {
          dispatch(
            showMessage({
              message: "Successfully Deleted",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
            })
          );
          dispatch({
            type: DELETE_COLLECTION,
            payload: collectionData,
          });
        }
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to delete COLLECTION data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

export function removeUserDialog() {
  return {
    type: REMOVE_USER_DIALOG,
  };
}

export function openNewUserDialog() {
  return {
    type: OPEN_NEW_USER_DIALOG,
  };
}

export function closeNewUserDialog() {
  return {
    type: CLOSE_NEW_USER_DIALOG,
  };
}

export function openEditUserDialog(data) {
  return {
    type: OPEN_EDIT_USER_DIALOG,
    data,
  };
}

export function closeEditUserDialog() {
  return {
    type: CLOSE_EDIT_USER_DIALOG,
  };
}

export function addCollection(data) {
  return (dispatch) => {
    const request = axios.post(
      `${API_SERVER_URL}/api/collection/insert/collection`,
      {
        data,
      }
    );
    return request
      .then((response) => {
        dispatch(
          showMessage({
            message: "Successfully Inserted",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
        Promise.all([
          dispatch({
            type: ADD_COLLECTION,
          }),
        ]).then(() => dispatch(getCollection()));
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to add COLLECTION data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

export function updateCollection(data) {
  return (dispatch) => {
    const request = axios.post(
      `${API_SERVER_URL}/api/collection/update/collection`,
      {
        data,
      }
    );

    return request
      .then((response) => {
        dispatch(
          showMessage({
            message: "Successfully Updated",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
        Promise.all([
          dispatch({
            type: UPDATE_COLLECTION,
          }),
        ]).then(() => dispatch(getCollection()));
      })
      .catch((err) => {
        dispatch(
          showMessage({
            message: "Failed to update COLLECTION data",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
          })
        );
        console.log("err", err);
      });
  };
}

import axios from "axios";
import { API_SERVER_URL } from "app/main/config";
import { showMessage } from "app/store/actions/fuse";

export const GET_USERS = "[USERS APP] GET USERS";
export const CONF_PERMISSION = "[USERS APP] CONF PERMISSION";
export const SET_SEARCH_TEXT = "[USERS APP] SET SEARCH TEXT";
export const TOGGLE_IN_SELECTED_USERS = "[USERS APP] TOGGLE IN SELECTED USERS";
export const SELECT_ALL_USERS = "[USERS APP] SELECT ALL USERS";
export const DESELECT_ALL_USERS = "[USERS APP] DESELECT ALL USERS";
export const OPEN_NEW_USER_DIALOG = "[USERS APP] OPEN NEW USER DIALOG";
export const CLOSE_NEW_USER_DIALOG = "[USERS APP] CLOSE NEW USER DIALOG";
export const OPEN_EDIT_USER_DIALOG = "[USERS APP] OPEN EDIT USER DIALOG";
export const CLOSE_EDIT_USER_DIALOG = "[USERS APP] CLOSE EDIT USER DIALOG";
export const ADD_USER = "[USERS APP] ADD USER";
export const UPDATE_USER = "[USERS APP] UPDATE USER";
export const REMOVE_USER = "[USERS APP] REMOVE USER";
export const REMOVE_USERS = "[USERS APP] REMOVE USERS";
export const TOGGLE_STARRED_USER = "[USERS APP] TOGGLE STARRED USER";
export const TOGGLE_STARRED_USERS = "[USERS APP] TOGGLE STARRED USERS";
export const SET_USERS_STARRED = "[USERS APP] SET USERS STARRED ";

export function getUsers() {
  const request = axios.post(`${API_SERVER_URL}/api/user/get_user`);
  return (dispatch) => {
    request.then((response) => {
      return dispatch({
        type: GET_USERS,
        payload: response.data,
      });
    });
  };
}

export function setSearchText(event) {
  return {
    type: SET_SEARCH_TEXT,
    searchText: event.target.value,
  };
}

export function toggleInSelectedUsers(userId) {
  return {
    type: TOGGLE_IN_SELECTED_USERS,
    userId,
  };
}

export function selectAllUsers() {
  return {
    type: SELECT_ALL_USERS,
  };
}

export function deSelectAllUsers() {
  return {
    type: DESELECT_ALL_USERS,
  };
}

export function openNewUserDialog() {
  return {
    type: OPEN_NEW_USER_DIALOG,
  };
}

export function closeNewUserDialog() {
  return {
    type: CLOSE_NEW_USER_DIALOG,
  };
}

export function openEditUserDialog(data) {
  return {
    type: OPEN_EDIT_USER_DIALOG,
    data,
  };
}

export function closeEditUserDialog() {
  return {
    type: CLOSE_EDIT_USER_DIALOG,
  };
}

export function addUser(model) {
  return (dispatch) => {
    const request = axios.post(`${API_SERVER_URL}/api/user/insert`, {
      ...model,
    });

    return request.then((response) => {
      if (response.data.success) {
        dispatch(
          showMessage({
            message: "Successfully Inserted",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
        Promise.all([
          dispatch({
            type: ADD_USER,
          }),
        ]).then(() => dispatch(getUsers()));
      } else {
        dispatch(
          showMessage({
            message: "Username already exists",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "warning",
          })
        );
      }
    });
  };
}

export function updateUser(model, id) {
  return (dispatch) => {
    const request = axios.post(`${API_SERVER_URL}/api/user/update`, {
      model,
      id,
    });
    return request.then((response) => {
      if (response.data.success) {
        dispatch(
          showMessage({
            message: "Successfully Updated",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
        Promise.all([
          dispatch({
            type: UPDATE_USER,
          }),
        ]).then(() => dispatch(getUsers()));
      } else {
        dispatch(
          showMessage({
            message: "Username already exists",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "warning",
          })
        );
      }
    });
  };
}

export function removeUser(id) {
  return (dispatch) => {
    const request = axios.post(`${API_SERVER_URL}/api/user/remove`, {
      id,
    });

    return request.then((response) => {
      dispatch(
        showMessage({
          message: "Successfully Deleted",
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
      Promise.all([
        dispatch({
          type: REMOVE_USER,
        }),
      ]).then(() => dispatch(getUsers()));
    });
  };
}

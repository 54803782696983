import React, { Component } from "react";
import { beautifyJSON } from "app/main/config";
import { IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Table, Row, Tooltip, Modal, Button, Select } from "antd";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
const { Option } = Select;

const defaultPagination = {
  pageSizeOptions: ["10", "20", "50", "70"],
  showSizeChanger: true,
  size: "small",
  position: "top",
};

const styles = (theme) => ({
  button: {
    marginTop: "-12px",
  },
});

class ManufacturerSubgroupTableModal extends Component {
  render() {
    const { open, classes } = this.props;

    const columns = [
      {
        key: "manufacturer_subgroup_name",
        title: "Name",
        dataIndex: "name",
        width: 300,
        render: (text, record) => <span>{text}</span>,
      },
      {
        key: "basic_manufacturer_name",
        title: "Basic Manufacturer",
        dataIndex: "manufacturer_id",
        width: 300,
        render: (text, record) => <span>{text.name}</span>,
      },
      {
        key: "door_color_alias",
        title: "Door Style",
        dataIndex: "door_color_alias_ids",
        width: 800,
        render: (text) => {
          const result = [];
          const value = [];
          if (text[0] != undefined) {
            text.forEach((item) => {
              result.push(
                <Option key={item.id} value={item.id}>
                  {item.alias_name}
                </Option>
              );
              value.push(item.id);
            });
            return (
              <Select
                mode="multiple"
                value={value}
                disabled={true}
                dropdownStyle={{
                  zIndex: 10000,
                  maxHeight: 400,
                  overflow: "auto",
                }}
                style={{ width: "100%" }}
              >
                {result}
              </Select>
            );
          }
          return (
            <Select
              mode="multiple"
              disabled={true}
              dropdownStyle={{
                zIndex: 10000,
                maxHeight: 400,
                overflow: "auto",
              }}
              style={{ width: "100%" }}
            />
          );
        },
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "id",
        width: 150,
        fixed: "right",
        render: (text, record) => (
          <Row>
            <IconButton
              className={classes.button}
              aria-label="Delete"
              onClick={(e) => {
                e.preventDefault();
                this.props.handleEdit(record);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Edit"
              onClick={(e) => {
                e.preventDefault();
                this.props.handleDelete(record);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Row>
        ),
      },
    ];
    return (
      <Modal
        zIndex={600}
        visible={open}
        title="Manufacturer Subgroups"
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        style={{ minWidth: "80%" }}
        footer={[
          <Button key="submit" type="primary" onClick={this.props.handleOk}>
            OK
          </Button>,
        ]}
      >
        <Table
          bordered
          rowKey="id"
          className="mtable"
          pagination={defaultPagination}
          columns={columns}
          dataSource={this.props.defaultValue}
          indentSize={20}
          scroll={{ x: 1550, y: 500 }}
        />
      </Modal>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  ManufacturerSubgroupTableModal
);

import history from "@history";
import jwtService from "app/services/jwtService";
import { setUserData } from "./user.actions";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export function submitLogin(values) {
  return (dispatch) =>
    jwtService
      .signInWithEmailAndPassword(values)
      .then((user) => {
        dispatch(setUserData(user));

        return dispatch({
          type: LOGIN_SUCCESS,
        });
      })
      .catch((error) => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error,
        });
      });
}

export function logout() {
  return (dispatch) => {
    jwtService.logout();
    history.push({
      pathname: "/login",
    });
    return dispatch({
      type: LOGOUT,
    });
  };
}

export function login() {
  return (dispatch) => {
    return dispatch({
      type: LOGIN_SUCCESS,
    });
  };
}

import Manufacturer from "./Manufacturer";
import { authRoles } from "app/auth";
export const ManufacturerConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/manufacturer",
      component: Manufacturer,
    },
  ],
};

import Cabinet from "./Cabinet";
import { authRoles } from "app/auth";
export const CabinetConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.designer,
  routes: [
    {
      path: "/cabinet/cabinet",
      component: Cabinet,
    },
  ],
};
